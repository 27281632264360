import React, { useEffect, useState } from 'react'
import { Element, useNode } from '@craftjs/core';
import { FormControl, FormLabel } from '@mui/material';
import { InputText } from 'primereact/inputtext';
import { RichText } from './RichText';
import { InputNumber } from 'primereact/inputnumber';

export const Panels = ({ header, background, color, fontSize }) => {
    const { connectors: { connect, drag } } = useNode();
    const [id, setId] = useState('')
    useEffect(() => {
        const makeid = () => {
            var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

            for (var i = 0; i < 5; i++)
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            return text

        }
        setId(makeid())
    }, [])
    const css = (`
    div.${id}{
        position: relative;
      }
      .${id} input{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        opacity:0;
        visibility: 0;
      }
      .${id} h6{
        background:${background || 'steelblue'};
        color:${color || 'white'};
        padding:10px;
        font-size: ${fontSize}px;
        margin: 2px;
        position: relative;
      }
      .${id} label::before{
        content:"";
        display: inline-block;
        border: 6px solid transparent;
        border-left:10px solid ${color || 'white'};
      }
      .${id} label{
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
      }
      div.p{
        display:none;
        overflow: hidden;
        transition:display 0.5s;
        background-color: white;
        box-shadow:0 0 10px 0 rgba(0, 0, 0, 0.2);
      }
      div.p div{
        padding:8px;
      }
      .${id} input:checked ~ h6 label::before{
        border-left:6px solid transparent;
        border-top:10px solid ${color || 'white'};
        margin-top:12px;
        margin-right:10px;
      }
      input:checked ~ h6 ~ div.p{
        display:block;
        overflow: auto;
      }
    `)

    return (
        <div className={id} ref={ref => connect(drag(ref))}>
            <input type="checkbox" id={id} />
            <h6><label htmlFor={id}>{header || 'accordion header'}</label></h6>
            <div className="p">
                <Element id='Richtext' srcDoc={'<p>This is Rich text sample</p>'} is={RichText} />
            </div>
            <style>
                {css}
            </style>
        </div>
    )
}

const PanelSetting = () => {
    const { actions: { setProp }, props } = useNode((node) => ({
        props: node.data.props
    }));
    return (
        <>
            <FormControl component="fieldset" className="mb-3">
                <FormLabel component="legend">Header Text</FormLabel>
                <InputText autoResize id="" type="text" value={props.header || ''} onChange={(e) => setProp(props => props.header = e.target.value)} />
            </FormControl>
            <FormControl fullWidth={true} margin="normal" component="fieldset">
                <FormLabel component="legend">Header Text Color</FormLabel>
                <InputText value={props.color} onChange={e => { setProp(props => props.color = e.target.value) }} />
            </FormControl>
            <FormControl fullWidth={true} margin="normal" component="fieldset">
                <FormLabel component="legend">Background Color</FormLabel>
                <InputText value={props.background} onChange={e => { setProp(props => props.background = e.target.value) }} />
            </FormControl>
            <FormControl size="small" component="fieldset">
                <FormLabel component="legend">Font size</FormLabel>
                <InputNumber value={props.fontSize} onChange={(e) => { setProp(props => props.fontSize = e.value) }} />
            </FormControl>
        </>
    )
}
Panels.craft = {
    related: {
        settings: PanelSetting
    }
}