import React, { Suspense } from 'react'
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from '../firebase';
import { useLocation } from 'react-router-dom';
import App from '../components/menu/App';
import Load from '../components/Load';

function Preview() {
  const [pages, setPages] = useState([])
  const [loading, setLoading] = useState(false)
  const params = new URLSearchParams(useLocation().search);
  const title = params.get("title");

  useEffect(() => {
    setLoading(true)
    const contentRef = collection(db, 'PageTemplate');
    const q = query(contentRef, where('title', '==', `${title}`))
    onSnapshot(q, querySnapshot => {
      setPages(querySnapshot.docs.map((doc) => (
        doc.data().html
      )));
    })
    setLoading(false)
  }, [title])
  if (loading) {
    return <Load />
  } else {
    return (
      <div className="Preview" style={{ background: 'white' }}>
        <App />
        {pages.map((item) => <div dangerouslySetInnerHTML={{ __html: item }} />)}
      </div>
    )
  }
}

export default Preview