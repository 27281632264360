import { createUserWithEmailAndPassword } from 'firebase/auth'
import { doc, setDoc, Timestamp } from 'firebase/firestore'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { auth, db } from '../firebase'

function Register() {
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [passwordconf, setPasswordconf] = useState(true)

  const handleConfirm = (e) =>{
    let confirmation = e.target.value
    if(confirmation!==password & confirmation!==undefined){
      setPasswordconf(false)
    }else { 
      setPasswordconf(true)
    }
  }
const history = useHistory()

const handleSubmit = async (e) =>{
    e.preventDefault();
    try {
        const result = await createUserWithEmailAndPassword(auth, userName, password);
        await setDoc(doc(db, "users", result.user.uid), {
            uid: result.user.uid,
            email: userName,
            password: password,
            createdAt: Timestamp.fromDate(new Date()),
        });
        setUserName('')
        setPassword('')
        history.push("/cms");
    } catch (err) {
        console.log(err)
    }
}

  return (
    <div className="grid container login">
      <div className="surface-card p-4 shadow-2 border-round loginContainer">
        <div className="text-center mb-5" style={{flex: '4'}}>
          <img src="images/blocks/logos/hyper.svg" alt="hyper" height="50" className="mb-3" />
          <div className="text-900 text-3xl font-medium mb-3">Welcome To WR CMS</div>
          {/* <span className="text-600 font-medium line-height-3">Don't have an account?</span>
        <a className="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Create today!</a> */}
        </div>

        <form style={{ flex: '6' }} onSubmit={handleSubmit}>
          <label htmlFor="email1" className="block text-900 font-medium mb-2">Email</label>
          <InputText id="email1" type="email" value={userName} required onChange={e => setUserName(e.target.value)} className="w-full mb-3" />

          <label htmlFor="password1" className="block text-900 font-medium mb-2">Password</label>
          <InputText id="password1" required value={password} onChange={(e) => setPassword(e.target.value)} type="password" className="w-full mb-3" />
          <label htmlFor="password2" className="block text-900 font-medium mb-2">Confirm Password</label>
          <InputText id="password2" required onChange={handleConfirm} type="password" className="w-full mb-3" />
          {!passwordconf && <span className='invalid-password'>The password you entered are not the same</span>}
          {/* <div className="flex align-items-center justify-content-between mb-6">
            <div className="flex align-items-center">
                <Checkbox inputId="rememberme1" binary className="mr-2" onChange={e => setChecked(e.checked)} checked={checked} />
                <label htmlFor="rememberme1">Remember me</label>
            </div>
            <a className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Forgot password?</a>
        </div> */}

          <Button label="Create Account" disabled={!passwordconf} style={{ marginTop: '35px' }} icon="pi pi-user" className="w-full mb-3" />
        </form>
      </div>
    </div>
  )
}

export default Register