import React, { useEffect, useState } from 'react'
import { Element, useNode } from '@craftjs/core';
import { MaterialButton } from './MaterialButton';

export const Tags = () => {
    const { connectors: { connect, drag } } = useNode();
    const [id, setId] = useState('')
    useEffect(() => {
        const makeid = () => {
            var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

            for (var i = 0; i < 5; i++)
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            return text

        }
        setId(makeid())
    }, [])
    return (
        <div ref={ref => connect(drag(ref))} >
            <Element is={MaterialButton}
                className="mr-2"
                label={'Tags'}
                id="tags"
                fontSize={10}
                borderRadius={10}
                borderThick={0}
                paddingLR={10}
                paddingTB={2}
                color="white"
                background="purple"
                HoverBackground={"purple"}>
            </Element>
        </div>
    )
}

