import { FormControl, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'
import { Editor } from '@tinymce/tinymce-react'
import React, { useEffect, useRef, useState } from 'react'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { Link } from 'react-router-dom'
import { Checkbox } from 'primereact/checkbox';
import { db, storage } from '../../firebase'
import { addDoc, collection, onSnapshot, query, Timestamp } from 'firebase/firestore'
import { Cancel } from '@mui/icons-material';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { Toast } from 'primereact/toast';

function AddProduct() {
    const editorRef = useRef(null)
    const [description, setDescription] = useState('')
    const [vignette, setVignette] = useState([])
    const [size, setSize] = useState([])
    const [name, setName] = useState('')
    const [categorie, setCategorie] = useState([])
    const [selectedCategorie, setSelectedCategorie] = useState([])
    const [loading, setLoading] = useState(false)
    const toast = useRef(null)
    const [price, setPrice] = useState('')
    const [quantity, setQuantity] = useState('')

    useEffect(() => {
        const contentRef = collection(db, 'ProductCategorie');
        const q = query(contentRef)
        onSnapshot(q, querySnapshot => {
            setCategorie(querySnapshot.docs.map((doc) => ((
                doc.data()
            ))));
        })
    }, [])
    const HandleName = (e) => {
        setName(e.target.value)
    }
    const onSizeChange = (e) => {
        let selectedSizes = [...size];
        if (e.checked)
            selectedSizes.push(e.value);
        else
            selectedSizes.splice(selectedSizes.indexOf(e.value), 1);

        setSize(selectedSizes);
    }
    const handleChange = (e) => {
        let images = []
        for (let i = 0; i < e.target.files.length; i++) {
            const newImage = e.target.files[i];
            const url = URL.createObjectURL(newImage)
            images.push({
                src: url,
                imageData: newImage
            })

        }
        setVignette([...vignette, ...images]);
    }
    const deleteFile = (e) => {
        const s = vignette.filter((item, index) => index !== e);
        setVignette(s);
    }
    const SaveProduct = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            let url = []
            for (let index = 0; index < vignette.length; index++) {
                const image = vignette[index];
                const fileRef = ref(storage, `Product/${new Date().getTime()} - ${image.imageData.name}`);
                const snap = await uploadBytes(fileRef, image.imageData);
                const dlUrl = await getDownloadURL(ref(storage, snap.ref.fullPath))
                url.push(dlUrl)
            }
            await addDoc(collection(db, 'ProductItem'), {
                name,
                description,
                quantity,
                price,
                size,
                url,
                Commande: 0,
                categorie: selectedCategorie,
                createdAt: Timestamp.fromDate(new Date()),
            })
            setLoading(false)
            toast.current.show({ severity: 'success', summary: 'Confirmation', detail: 'Categorie Added successfully', life: 3000 });
        } catch (error) {
            console.log(error)
        }
    }
    console.log(selectedCategorie)

    return (
        <>
            <Toast ref={toast} />
            <div className='card' style={{ background: 'transparent' }}><Link to={'/cms/Products'} style={{ color: 'black', fontWeight: 'bold' }}><i className='pi pi-fw pi-arrow-left'></i> Products</Link></div>
            <div className='card' style={{ background: 'transparent' }}><h3 style={{ fontWeight: 'bold' }}>Add Products</h3></div>
            <form onSubmit={SaveProduct}>
                <div className='card'>
                    <h4>Products details</h4>
                    <TextField variant="outlined" margin='normal' label="Product's Name" onChange={HandleName} required />
                    <Editor
                        apiKey={process.env.REACT_APP_EDITOR_API_KEY}
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={'<p>Add Description text here</p>'}
                        onEditorChange={(newValue, editor) => setDescription(newValue)}
                        init={{
                            selector: 'textarea',
                            height: 300,
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'charmap',
                                'anchor', 'insertdatetime', 'wordcount',
                            ],
                            toolbar: 'undo redo | blocks ' +
                                'bold italic underline forecolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat| link',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                    />
                </div>
                <div className='card'>
                    <h4>Price</h4>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="outlined-adornment-amount">Price</InputLabel>
                        <OutlinedInput
                            onChange={(e) => setPrice(e.target.value)}
                            id="outlined-adornment-amount"
                            type='number'
                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                            label="Price"
                            required
                        />
                    </FormControl>
                </div>
                <div className='card'>
                    <h4>Quantity</h4>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="outlined-adornment-amount">Quantity</InputLabel>
                        <OutlinedInput
                            onChange={(e) => setQuantity(e.target.value)}
                            id="outlined-adornment-amount"
                            type='number'
                            label="Quantity"
                            required
                        />
                    </FormControl>
                </div>
                <div className='card'>
                    <div className='grid' style={{ justifyContent: 'space-between' }}>
                        <h4>Variant</h4>
                        <Button label='Add' icon='pi pi-fw pi-plus' />
                    </div>
                    <FormControl fullWidth>
                        <h5>Size</h5>
                        <div className='grid'>
                            <div className='md:col-3 sm:col-4 xs:col-12'>
                                <Checkbox className='mr-2' onChange={onSizeChange} checked={size.includes('XS')} inputId='cb0' value={'XS'}></Checkbox>
                                <label htmlFor="cb0" className="p-checkbox-label">Extra Small</label>
                            </div>
                            <div className='md:col-3 sm:col-4 xs:col-12'>
                                <Checkbox className='mr-2' onChange={onSizeChange} checked={size.includes('S')} inputId='cb1' value={'S'}></Checkbox>
                                <label htmlFor="cb1" className="p-checkbox-label">Small</label>
                            </div>
                            <div className='md:col-3 sm:col-4 xs:col-12'>
                                <Checkbox className='mr-2' onChange={onSizeChange} checked={size.includes('M')} inputId='cb2' value={'M'}></Checkbox>
                                <label htmlFor="cb2" className="p-checkbox-label">Medium</label>
                            </div>
                            <div className='md:col-3 sm:col-4 xs:col-12'>
                                <Checkbox className='mr-2' onChange={onSizeChange} checked={size.includes('L')} inputId='cb3' value={'L'}></Checkbox>
                                <label htmlFor="cb3" className="p-checkbox-label">Large</label>
                            </div>
                            <div className='md:col-3 sm:col-4 xs:col-12'>
                                <Checkbox className='mr-2' onChange={onSizeChange} checked={size.includes('XL')} inputId='cb4' value={'XL'}></Checkbox>
                                <label htmlFor="cb4" className="p-checkbox-label">Extra Large</label>
                            </div>
                            <div className='md:col-3 sm:col-4 xs:col-12'>
                                <Checkbox className='mr-2' onChange={onSizeChange} checked={size.includes('XXL')} inputId='cb5' value={'XXL'}></Checkbox>
                                <label htmlFor="cb5" className="p-checkbox-label">Extra Extra Large</label>
                            </div>
                            <div className='md:col-3 sm:col-4 xs:col-12'>
                                <Checkbox className='mr-2' onChange={onSizeChange} checked={size.includes('2XXL')} inputId='cb6' value={'2XXL'}></Checkbox>
                                <label htmlFor="cb6" className="p-checkbox-label">Two Extra Extra Large</label>
                            </div>
                            <div className='md:col-3 sm:col-4 xs:col-12'>
                                <Checkbox className='mr-2' onChange={onSizeChange} checked={size.includes('3XXL')} inputId='cb6' value={'3XXL'}></Checkbox>
                                <label htmlFor="cb6" className="p-checkbox-label">Three Extra Extra Large</label>
                            </div>
                        </div>
                    </FormControl>
                </div>
                <div className='card'>
                    <h4>Categorie</h4>
                    <MultiSelect display="chip" optionLabel="name" value={selectedCategorie} options={categorie} onChange={(e) => setSelectedCategorie(e.value)} />
                </div>
                <div className='card'>
                    <h4>References Images</h4>
                    <label htmlFor="formFile" style={{ marginTop: "10px", cursor: "pointer" }}>
                        <AddAPhotoIcon fontSize="large" />
                    </label>
                    <input type="file" name="" multiple id="formFile" style={{ display: "none" }} accept='image/*' onChange={handleChange} />
                    {vignette.length > 0 && (
                        <>
                            <hr />
                            <div className="grid">
                                {vignette.length > 0 && vignette.map((item, index) =>
                                    <div key={item} className="shareImgContainer" style={{ width: '25%' }}>
                                        <img className="shareImgUp" src={item.src} />
                                        <Cancel className="shareCancelImg" onClick={() => deleteFile(index)} />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
                <Button disabled={loading} label={loading ? 'Saving...' : 'Save'} icon='pi pi-fw pi-save' type='submit' className='p-button-success' />
            </form>

        </>
    )
}

export default AddProduct