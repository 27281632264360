import { collection, onSnapshot, query } from 'firebase/firestore'
import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { db } from '../../firebase'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column'

function Products() {
  const [categorie, setCategorie] = useState([])

  useEffect(() => {
    const contentRef = collection(db, 'ProductItem');
    const q = query(contentRef)
    onSnapshot(q, querySnapshot => {
      setCategorie(querySnapshot.docs.map((doc) => ({
        data: doc.data(),
        id: doc.id
      }
      )));
    })
  }, [])
  const nameBodyField = (rowData) => {
    return <p><b>{rowData.data?.name}</b></p>
  }
  const tagBodyField = (rowData) => {
    return <p>{rowData.data?.quantity}</p>
  }
  const commandBodyField = (rowData) => {
    return <p>{rowData.data?.command || 0}</p>
  }
  const salesBodyField = (rowData) => {
    return <p>{rowData.data?.price * rowData.data?.command || 0.00}€</p>
  }
  const imageBodyTemplate = (rowData) => {
    return rowData.data?.url?.map((image) => <img style={{ width: '135px' }} src={image} className="product-image" />)
  }

  return (
    <>
      <div className='card productHeader'>
        <h3><b>Products</b></h3>
        <div><Link to={"/cms/Add"}><Button label='Add' icon='pi pi-fw pi-plus' /></Link></div>
      </div>
      <div className='card'>
        <DataTable value={categorie} >
          <Column header="Name" body={nameBodyField}></Column>
          <Column header="Restant" body={tagBodyField}></Column>
          <Column header="Image" body={imageBodyTemplate}></Column>
          <Column header="Commande" body={commandBodyField}></Column>
          <Column header="Sales" body={salesBodyField}></Column>
        </DataTable>
      </div>
    </>
  )
}

export default Products
