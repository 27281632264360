import React from 'react'
import { Panel } from 'primereact/panel';
import App from '../components/menu/App'

function MenuBluilder() {

  return (
    <div>
      <App />
      <Panel header='Create your Menu'>

      </Panel>
    </div>
  )
}

export default MenuBluilder