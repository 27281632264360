import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom';
import { AuthContext } from './context/auth'
import App from './App';

function LoginBlock() {
    const {user} = useContext(AuthContext);
    let history = useHistory()
  return (
    <>  { user ? <App /> : history.push('/login')}
    </>
  )
}

export default LoginBlock