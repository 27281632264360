import React, { useEffect, useState } from 'react'
import { ContainerDefaultProps } from './Container';
import { Element, useNode } from '@craftjs/core';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Helmet } from 'react-helmet';
import defaultImg from '../../assets/layout/galleria6.jpg'
import { InputLabel, MenuItem, Select } from "@mui/material";
import { FormControl, FormLabel } from "@mui/material";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { InputNumber } from "primereact/inputnumber";
import { Panel } from 'primereact/panel';
import { Cancel } from "@mui/icons-material";
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../../firebase';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Bcss from './bootstrap/carousel.css'


export const CarouselItem = ({
    children,
    className,
    background,
    backgroundImage,
    paddingTop,
    paddingTopM,
    paddingTopT,
    paddingRight,
    paddingRightM,
    paddingRightT,
    paddingLeft,
    paddingLeftM,
    paddingLeftT,
    paddingBottomM,
    paddingBottomT,
    paddingBottom,
    height,
    heightM,
    heightT,
    marginLeft,
    marginLeftM,
    marginLeftT,
    marginBottom,
    marginBottomM,
    marginBottomT,
    marginRight,
    marginRightM,
    marginRightT,
    alignItems,
    alignItemsM,
    alignItemsT,
    justifyContent,
    justifyContentM,
    justifyContentT,
    marginTop = 0,
    marginTopM = 0,
    marginTopT = 0,
    textAlign,
    textAlignM,
    textAlignT,
    width,
    widthM,
    widthT,
    display,
    displayM,
    displayT,
    Wunit,
    WunitT,
    WunitM,
    Hunit,
    HunitM,
    HunitT,
    flexDirection,
    flexDirectionT,
    flexDirectionM
}) => {
    const [id, setId] = useState('')
    useEffect(() => {
        const makeid = () => {
            var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

            for (var i = 0; i < 5; i++)
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            return text

        }
        setId(makeid())
    }, [])
    const css = (`
  #${id}{
    background-image:url('${backgroundImage}');
    background:${background};
    padding-top: ${paddingTop}px ;
    padding-bottom: ${paddingBottom}px ;
    padding-left: ${paddingLeft}px ;
    padding-right: ${paddingRight}px ;
    margin-top: ${marginTop}px ;
    margin-bottom: ${marginBottom}px ;
    margin-left: ${marginLeft}px ;
    margin-right: ${marginRight}px ;
    text-align: ${textAlign} ;
    display: ${display} ;
    justify-content: ${justifyContent} ;
    align-items: ${alignItems} ;
    height: ${height}${Hunit} ;
    width: ${width}${Wunit};
    flex-direction: ${flexDirection};
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media screen and (max-width: 770px){
    #${id}{
      padding-top: ${paddingTopT}px ;
      padding-bottom: ${paddingBottomT}px ;
      padding-left: ${paddingLeftT}px ;
      padding-right: ${paddingRightT}px ;
      margin-top: ${marginTopT}px ;
      margin-bottom: ${marginBottomT}px ;
      margin-left: ${marginLeftT}px ;
      margin-right: ${marginRightT}px ;
      text-align: ${textAlignT} ;
      display: ${displayT} ;
      justify-content: ${justifyContentT} ;
      align-items: ${alignItemsT} ;
      height: ${heightT}${HunitT};
      width: ${widthT}${WunitT};
      flex-direction: ${flexDirectionT};
    }   
  }
  @media screen and (max-width:450px){
    #${id}{
      padding-top: ${paddingTopM}px ;
      padding-bottom: ${paddingBottomM}px ;
      padding-left: ${paddingLeftM}px ;
      padding-right: ${paddingRightM}px ;
      margin-top: ${marginTopM}px ;
      margin-bottom: ${marginBottomM}px ;
      margin-left: ${marginLeftM}px ;
      margin-right: ${marginRightM}px ;
      text-align: ${textAlignM} ;
      display: ${displayM} ;
      justify-content: ${justifyContentM} ;
      align-items: ${alignItemsM};
      height: ${heightM}${HunitM};
      width: ${widthM}${WunitM};
      flex-direction: ${flexDirectionM};
    }
  }
  `)
    const { connectors: { connect } } = useNode();
    return (
        <>
            <div ref={connect} id={id} className={className}>
                {children}
            </div>
            <style>
                {css}
            </style>
        </>
    )
}
export const CarouselUser = ({ SlideNumber }) => {
    const { connectors: { connect, drag } } = useNode()
    return (
        <div ref={ref => connect(drag(ref))}>
            <link rel="stylesheet" href={Bcss} />
            <div id="carouselExampleFade" className="carousel slide carousel-fade" data-ride="carousel">
                <div className="carousel-inner">
                    <Element is={CarouselItem} id='item1' className="carousel-item active" canvas>
                        <img className="d-block w-100" src={defaultImg} alt="First slide" />
                    </Element>
                    <Element is={CarouselItem} id='item2' className="carousel-item" canvas>
                        <img className="d-block w-100" src={defaultImg} alt="First slide" />
                    </Element>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleFade" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleFade" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>
            </div>
    )
}

export const CarouselGeneralSetting = () => {
    const { actions: { setProp }, props } = useNode((node) => ({
        props: node.data.props
    }));

    return (
        <div>
            <FormLabel component="legend">Number of Slide</FormLabel>
            <InputNumber value={props.SlideNumber} onChange={(e) => setProp(props => props.SlideNumber = e.value)} />
        </div>
    )
}

export const CarouselItemSetting = () => {
    const [vignette, setVignette] = useState('')
    const { actions: { setProp }, props } = useNode((node) => ({
        props: node.data.props
    }));
    const HandleUpload = async (e) => {
        e.preventDefault()
        let src
        const fileRef = ref(storage, `images/${new Date().getTime()} - ${vignette.name}`);
        const snap = await uploadBytes(fileRef, vignette);
        const dlUrl = await getDownloadURL(ref(storage, snap.ref.fullPath));
        src = dlUrl
        setProp(props => props.backgroundImage = src)
        setVignette(null)
    }

    return (
        <div>
            <Panel header="General Settings" toggleable>
                <FormControl component="fieldset">
                    <label htmlFor="formFile" style={{ marginTop: "10px", cursor: "pointer" }}>
                        <AddAPhotoIcon fontSize="large" />
                    </label>
                    <input type="file" name="" id="formFile" style={{ display: "none" }} accept='image/*' onChange={(e) => setVignette(e.target.files[0])} />
                    {vignette && (
                        <>
                            <hr />
                            <FormLabel component="legend">Preview</FormLabel>
                            <div className="shareImgContainer">
                                <img className="shareImgUp" src={URL.createObjectURL(vignette)} alt="" />
                                <Cancel className="shareCancelImg" onClick={() => setVignette(null)} />
                            </div>
                            <Button label='Upload Picture' icon='pi pi-fw pi-send' onClick={HandleUpload} />
                            <hr />
                        </>
                    )}
                    {props.backgroundImage && (
                        <>
                            <hr />
                            <FormLabel component="legend">Actual Image</FormLabel>
                            <div className="shareImgContainer">
                                <img className="shareImgUp" src={props.backgroundImage} alt="" />
                            </div>
                            <hr />
                        </>
                    )}
                </FormControl>
                <FormControl fullWidth={true} margin="normal" component="fieldset">
                    <FormLabel component="legend">Background Color</FormLabel>
                    <InputText value={props.background || '#fff'} onChange={e => { setProp(props => props.background = e.target.value) }} />
                </FormControl>
            </Panel>
            <Panel toggleable header="Desktop">
                <FormControl fullWidth={true} margin="normal" component="fieldset">
                    <Panel toggleable header="Padding">
                        <FormLabel component="legend">Padding Top</FormLabel>
                        <InputNumber value={props.paddingTop} onChange={(e) => setProp(props => props.paddingTop = e.value)} />
                        <FormLabel component="legend">Padding Bottom</FormLabel>
                        <InputNumber value={props.paddingBottom} onChange={(e) => setProp(props => props.paddingBottom = e.value)} />
                        <FormLabel component="legend">Padding Left</FormLabel>
                        <InputNumber value={props.paddingLeft} onChange={(e) => setProp(props => props.paddingLeft = e.value)} />
                        <FormLabel component="legend">Padding Right</FormLabel>
                        <InputNumber value={props.paddingRight} onChange={(e) => setProp(props => props.paddingRight = e.value)} />
                    </Panel>
                </FormControl>
                <FormControl fullWidth={true} margin="normal" component="fieldset">
                    <Panel toggleable header="Margin">
                        <FormLabel component="legend">Margin Top</FormLabel>
                        <InputNumber value={props.marginTop} onChange={(e) => setProp(props => props.marginTop = e.value)} />
                        <FormLabel component="legend">Margin Bottom</FormLabel>
                        <InputNumber value={props.marginBottom} onChange={(e) => setProp(props => props.marginBottom = e.value)} />
                        <FormLabel component="legend">Margin Left</FormLabel>
                        <InputNumber value={props.marginLeft} onChange={(e) => setProp(props => props.marginLeft = e.value)} />
                        <FormLabel component="legend">Margin Right</FormLabel>
                        <InputNumber value={props.marginRight} onChange={(e) => setProp(props => props.marginRight = e.value)} />
                    </Panel>
                </FormControl>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControl margin="normal" component="fieldset">
                        <FormLabel component="legend">width</FormLabel>
                        <InputNumber value={props.width} onChange={(e) => setProp(props => props.width = e.value)} />
                    </FormControl>
                    <FormControl margin="normal" component="fieldset">
                        <FormLabel component="legend">Units</FormLabel>
                        <Select
                            value={props.Wunit}
                            onChange={(e) => setProp(props => props.Wunit = e.target.value)}
                        >
                            <MenuItem value="">none</MenuItem>
                            <MenuItem value="px">px</MenuItem>
                            <MenuItem value="rem">rem</MenuItem>
                            <MenuItem value="%">percent</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControl margin="normal" component="fieldset">
                        <FormLabel component="legend">Height</FormLabel>
                        <InputNumber value={props.height} onChange={(e) => setProp(props => props.height = e.value)} />
                    </FormControl>
                    <FormControl margin="normal" component="fieldset">
                        <FormLabel component="legend">Units</FormLabel>
                        <Select
                            value={props.Hunit}
                            onChange={(e) => setProp(props => props.Hunit = e.target.value)}
                        >
                            <MenuItem value="">none</MenuItem>
                            <MenuItem value="px">px</MenuItem>
                            <MenuItem value="rem">rem</MenuItem>
                            <MenuItem value="%">percent</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <FormControl component="fieldset" margin="normal" fullWidth>
                    <InputLabel id="demo-simple-select-label">Display type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.display}
                        label="Display type"
                        onChange={(e) => setProp(props => props.display = e.target.value)}
                    >
                        <MenuItem value="flex">Flex</MenuItem>
                        <MenuItem value="">Inherit</MenuItem>
                        <MenuItem value="Block">Block</MenuItem>
                        <MenuItem value="None">None</MenuItem>
                    </Select>
                </FormControl>
                {props.display == 'flex' ?
                    <>
                        <FormControl component="fieldset" fullWidth margin="normal">
                            <InputLabel id="demo-simple-select-label">Flex Direction</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.flexDirection}
                                label="Justify content"
                                onChange={(e) => setProp(props => props.flexDirection = e.target.value)}
                            >
                                <MenuItem value="initial">Initial</MenuItem>
                                <MenuItem value="row">Row</MenuItem>
                                <MenuItem value="row-reverse">Row reverse</MenuItem>
                                <MenuItem value="column">Column</MenuItem>
                                <MenuItem value="column-reverse">Column reverse</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl component="fieldset" fullWidth margin="normal">
                            <InputLabel id="demo-simple-select-label">Justify content</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.justifyContent}
                                label="Justify content"
                                onChange={(e) => setProp(props => props.justifyContent = e.target.value)}
                            >
                                <MenuItem value="center">Center</MenuItem>
                                <MenuItem value="flex-start">Start</MenuItem>
                                <MenuItem value="flex-end">End</MenuItem>
                                <MenuItem value="space-between">Space-between</MenuItem>
                                <MenuItem value="space-around">Space-around</MenuItem>
                                <MenuItem value="space-evenly">Space-evenly</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl component="fieldset" fullWidth margin="normal">
                            <InputLabel id="demo-simple-select-label">Align Items</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.alignItems}
                                label="Align Items"
                                onChange={(e) => setProp(props => props.alignItems = e.target.value)}
                            >
                                <MenuItem value="center">Center</MenuItem>
                                <MenuItem value="flex-start">Start</MenuItem>
                                <MenuItem value="flex-end">End</MenuItem>
                                <MenuItem value="stretch">Stretch</MenuItem>
                            </Select>
                        </FormControl>
                    </>
                    : null
                }
                <FormControl component="fieldset" fullWidth margin="normal">
                    <InputLabel id="demo-simple-select-label">Text align</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.textAlign}
                        label="Text align"
                        onChange={(e) => setProp(props => props.textAlign = e.target.value)}
                    >
                        <MenuItem value="right">Right</MenuItem>
                        <MenuItem value="center">Center</MenuItem>
                        <MenuItem value="left">Left</MenuItem>
                    </Select>
                </FormControl>
            </Panel>
            <Panel toggleable header="Tablet">
                <FormControl fullWidth={true} margin="normal" component="fieldset">
                    <Panel toggleable header="Padding">
                        <FormLabel component="legend">Padding Top</FormLabel>
                        <InputNumber value={30 || props.paddingTopT} onChange={(e) => setProp(props => props.paddingTopT = e.value)} />
                        <FormLabel component="legend">Padding Bottom</FormLabel>
                        <InputNumber value={30 || props.paddingBottomT} onChange={(e) => setProp(props => props.paddingBottomT = e.value)} />
                        <FormLabel component="legend">Padding Left</FormLabel>
                        <InputNumber value={30 || props.paddingLeftT} onChange={(e) => setProp(props => props.paddingLeftT = e.value)} />
                        <FormLabel component="legend">Padding Right</FormLabel>
                        <InputNumber value={30 || props.paddingRightT} onChange={(e) => setProp(props => props.paddingRightT = e.value)} />
                    </Panel>
                </FormControl>
                <FormControl fullWidth={true} margin="normal" component="fieldset">
                    <Panel toggleable header="Margin">
                        <FormLabel component="legend">Margin Top</FormLabel>
                        <InputNumber value={props.marginTop || props.marginTopT} onChange={(e) => setProp(props => props.marginTopT = e.value)} />
                        <FormLabel component="legend">Margin Bottom</FormLabel>
                        <InputNumber value={props.marginBottom || props.marginBottomT} onChange={(e) => setProp(props => props.marginBottomT = e.value)} />
                        <FormLabel component="legend">Margin Left</FormLabel>
                        <InputNumber value={props.marginLeft || props.marginLeftT} onChange={(e) => setProp(props => props.marginLeftT = e.value)} />
                        <FormLabel component="legend">Margin Right</FormLabel>
                        <InputNumber value={props.marginRight || props.marginRightT} onChange={(e) => setProp(props => props.marginRightT = e.value)} />
                    </Panel>
                </FormControl>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControl margin="normal" component="fieldset">
                        <FormLabel component="legend">width</FormLabel>
                        <InputNumber value={props.widthT} onChange={(e) => setProp(props => props.widthT = e.value)} />
                    </FormControl>
                    <FormControl margin="normal" component="fieldset">
                        <FormLabel component="legend">Units</FormLabel>
                        <Select
                            value={props.WunitT}
                            onChange={(e) => setProp(props => props.WunitT = e.target.value)}
                        >
                            <MenuItem value="">none</MenuItem>
                            <MenuItem value="px">px</MenuItem>
                            <MenuItem value="rem">rem</MenuItem>
                            <MenuItem value="%">percent</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControl margin="normal" component="fieldset">
                        <FormLabel component="legend">Height</FormLabel>
                        <InputNumber value={props.heightT} onChange={(e) => setProp(props => props.heightT = e.value)} />
                    </FormControl>
                    <FormControl margin="normal" component="fieldset">
                        <FormLabel component="legend">Units</FormLabel>
                        <Select
                            value={props.HunitT}
                            onChange={(e) => setProp(props => props.HunitT = e.target.value)}
                        >
                            <MenuItem value="">none</MenuItem>
                            <MenuItem value="px">px</MenuItem>
                            <MenuItem value="rem">rem</MenuItem>
                            <MenuItem value="%">percent</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <FormControl component="fieldset" margin="normal" fullWidth>
                    <InputLabel id="demo-simple-select-label">Display type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.displayT}
                        label="Display type"
                        onChange={(e) => setProp(props => props.displayT = e.target.value)}
                    >
                        <MenuItem value="flex">Flex</MenuItem>
                        <MenuItem value="Block">Block</MenuItem>
                        <MenuItem value="None">None</MenuItem>
                    </Select>
                </FormControl>
                {props.displayT == 'flex' ?
                    <>
                        <FormControl component="fieldset" fullWidth margin="normal">
                            <InputLabel id="demo-simple-select-label">Flex Direction</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.flexDirectionT}
                                label="Justify content"
                                onChange={(e) => setProp(props => props.flexDirectionT = e.target.value)}
                            >
                                <MenuItem value="initial">Initial</MenuItem>
                                <MenuItem value="row">Row</MenuItem>
                                <MenuItem value="row-reverse">Row reverse</MenuItem>
                                <MenuItem value="column">Column</MenuItem>
                                <MenuItem value="column-reverse">Column reverse</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl component="fieldset" fullWidth margin="normal">
                            <InputLabel id="demo-simple-select-label">Justify content</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.justifyContentT}
                                label="Justify content"
                                onChange={(e) => setProp(props => props.justifyContentT = e.target.value)}
                            >
                                <MenuItem value="center">Center</MenuItem>
                                <MenuItem value="flex-start">Start</MenuItem>
                                <MenuItem value="flex-end">End</MenuItem>
                                <MenuItem value="space-between">Space-between</MenuItem>
                                <MenuItem value="space-around">Space-around</MenuItem>
                                <MenuItem value="space-evenly">Space-evenly</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl component="fieldset" fullWidth margin="normal">
                            <InputLabel id="demo-simple-select-label">Align Items</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.alignItemsT}
                                label="Align Items"
                                onChange={(e) => setProp(props => props.alignItemsT = e.target.value)}
                            >
                                <MenuItem value="center">Center</MenuItem>
                                <MenuItem value="flex-start">Start</MenuItem>
                                <MenuItem value="flex-end">End</MenuItem>
                                <MenuItem value="stretch">Stretch</MenuItem>
                            </Select>
                        </FormControl>
                    </>
                    : null
                }
                <FormControl component="fieldset" fullWidth margin="normal">
                    <InputLabel id="demo-simple-select-label">Text align</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.textAlignT}
                        label="Text align"
                        onChange={(e) => setProp(props => props.textAlignT = e.target.value)}
                    >
                        <MenuItem value="right">Right</MenuItem>
                        <MenuItem value="center">Center</MenuItem>
                        <MenuItem value="left">Left</MenuItem>
                    </Select>
                </FormControl>
            </Panel>
            <Panel toggleable header="Mobile">
                <FormControl fullWidth={true} margin="normal" component="fieldset">
                    <Panel toggleable header="Padding">
                        <FormLabel component="legend">Padding Top</FormLabel>
                        <InputNumber value={props.paddingTop || props.paddingTopM} onChange={(e) => setProp(props => props.paddingTopM = e.value)} />
                        <FormLabel component="legend">Padding Bottom</FormLabel>
                        <InputNumber value={props.paddingBottom || props.paddingBottomM} onChange={(e) => setProp(props => props.paddingBottomM = e.value)} />
                        <FormLabel component="legend">Padding Left</FormLabel>
                        <InputNumber value={props.paddingLeft || props.paddingLeftM} onChange={(e) => setProp(props => props.paddingLeftM = e.value)} />
                        <FormLabel component="legend">Padding Right</FormLabel>
                        <InputNumber value={props.paddingRight || props.paddingRightM} onChange={(e) => setProp(props => props.paddingRightM = e.value)} />
                    </Panel>
                </FormControl>
                <FormControl fullWidth={true} margin="normal" component="fieldset">
                    <Panel toggleable header="Margin">
                        <FormLabel component="legend">Margin Top</FormLabel>
                        <InputNumber value={props.marginTop || props.marginTopM} onChange={(e) => setProp(props => props.marginTopM = e.value)} />
                        <FormLabel component="legend">Margin Bottom</FormLabel>
                        <InputNumber value={props.marginBottom || props.marginBottomM} onChange={(e) => setProp(props => props.marginBottomM = e.value)} />
                        <FormLabel component="legend">Margin Left</FormLabel>
                        <InputNumber value={props.marginLeft || props.marginLeftM} onChange={(e) => setProp(props => props.marginLeftM = e.value)} />
                        <FormLabel component="legend">Margin Right</FormLabel>
                        <InputNumber value={props.marginRight || props.marginRightM} onChange={(e) => setProp(props => props.marginRightM = e.value)} />
                    </Panel>
                </FormControl>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControl margin="normal" component="fieldset">
                        <FormLabel component="legend">width</FormLabel>
                        <InputNumber value={props.widthM} onChange={(e) => setProp(props => props.widthM = e.value)} />
                    </FormControl>
                    <FormControl margin="normal" component="fieldset">
                        <FormLabel component="legend">Units</FormLabel>
                        <Select
                            value={props.WunitM}
                            onChange={(e) => setProp(props => props.WunitM = e.target.value)}
                        >
                            <MenuItem value="">none</MenuItem>
                            <MenuItem value="px">px</MenuItem>
                            <MenuItem value="rem">rem</MenuItem>
                            <MenuItem value="%">percent</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControl margin="normal" component="fieldset">
                        <FormLabel component="legend">Height</FormLabel>
                        <InputNumber value={props.heightM} onChange={(e) => setProp(props => props.heightM = e.value)} />
                    </FormControl>
                    <FormControl margin="normal" component="fieldset">
                        <FormLabel component="legend">Units</FormLabel>
                        <Select
                            value={props.HunitM}
                            onChange={(e) => setProp(props => props.HunitM = e.target.value)}
                        >
                            <MenuItem value="">none</MenuItem>
                            <MenuItem value="px">px</MenuItem>
                            <MenuItem value="rem">rem</MenuItem>
                            <MenuItem value="%">percent</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <FormControl component="fieldset" margin="normal" fullWidth>
                    <InputLabel id="demo-simple-select-label">Display type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.displayM}
                        label="Display type"
                        onChange={(e) => setProp(props => props.displayM = e.target.value)}
                    >
                        <MenuItem value="flex">Flex</MenuItem>
                        <MenuItem value="Block">Block</MenuItem>
                        <MenuItem value="None">None</MenuItem>
                    </Select>
                </FormControl>
                {props.displayM == 'flex' ?
                    <>
                        <FormControl component="fieldset" fullWidth margin="normal">
                            <InputLabel id="demo-simple-select-label">Flex Direction</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.flexDirectionM}
                                label="Justify content"
                                onChange={(e) => setProp(props => props.flexDirectionM = e.target.value)}
                            >
                                <MenuItem value="initial">Initial</MenuItem>
                                <MenuItem value="row">Row</MenuItem>
                                <MenuItem value="row-reverse">Row reverse</MenuItem>
                                <MenuItem value="column">Column</MenuItem>
                                <MenuItem value="column-reverse">Column reverse</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl component="fieldset" fullWidth margin="normal">
                            <InputLabel id="demo-simple-select-label">Justify content</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.justifyContentM}
                                label="Justify content"
                                onChange={(e) => setProp(props => props.justifyContentM = e.target.value)}
                            >
                                <MenuItem value="center">Center</MenuItem>
                                <MenuItem value="flex-start">Start</MenuItem>
                                <MenuItem value="flex-end">End</MenuItem>
                                <MenuItem value="space-between">Space-between</MenuItem>
                                <MenuItem value="space-around">Space-around</MenuItem>
                                <MenuItem value="space-evenly">Space-evenly</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl component="fieldset" fullWidth margin="normal">
                            <InputLabel id="demo-simple-select-label">Align Items</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.alignItemsM}
                                label="Align Items"
                                onChange={(e) => setProp(props => props.alignItemsM = e.target.value)}
                            >
                                <MenuItem value="center">Center</MenuItem>
                                <MenuItem value="flex-start">Start</MenuItem>
                                <MenuItem value="flex-end">End</MenuItem>
                                <MenuItem value="stretch">Stretch</MenuItem>
                            </Select>
                        </FormControl>
                    </>
                    : null
                }
                <FormControl component="fieldset" fullWidth margin="normal">
                    <InputLabel id="demo-simple-select-label">Text align</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.textAlignM}
                        label="Text align"
                        onChange={(e) => setProp(props => props.textAlignM = e.target.value)}
                    >
                        <MenuItem value="right">Right</MenuItem>
                        <MenuItem value="center">Center</MenuItem>
                        <MenuItem value="left">Left</MenuItem>
                    </Select>
                </FormControl>
            </Panel>
        </div>
    )
}

CarouselUser.craft = {
    related: {
        settings: CarouselGeneralSetting
    }
}

CarouselItem.craft = {
    related: {
        settings: CarouselItemSetting
    }
}