import { useNode } from '@craftjs/core';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Slider } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { InputNumber } from "primereact/inputnumber";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import defaultImg from './image/default.jpg'
import { Cancel } from '@mui/icons-material';
import { Button } from 'primereact/button';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { db, storage } from '../../firebase';
import { collection } from 'firebase/firestore';

export const Image = ({ src, width, height, objectFit, borderRadius, className }) => {
    const { connectors: { connect, drag } } = useNode();


    return (
        <img ref={ref => connect(drag(ref))} className={className} src={src || defaultImg} alt='' style={{ width: `${width}%`, borderRadius: `${borderRadius}px`, height: `${height}px`, objectFit: `${objectFit}` }} />
    )
}
const ImageSettings = () => {
    const [data, setData] = useState([]);
    const [vignette, setVignette] = useState('')
    const { actions: { setProp }, props } = useNode((node) => ({
        props: node.data.props
    }));
    const HandleUpload = async (e) => {
        e.preventDefault()
        let src
        const fileRef = ref(storage, `images/${new Date().getTime()} - ${vignette.name}`);
        const snap = await uploadBytes(fileRef, vignette);
        const dlUrl = await getDownloadURL(ref(storage, snap.ref.fullPath));
        src = dlUrl
        setProp(props => props.src = src)
        setVignette(null)
    }

    return (
        <>
            <FormControl component="fieldset">
                <label htmlFor="formFile" style={{ marginTop: "10px", cursor: "pointer" }}>
                    <AddAPhotoIcon fontSize="large" />
                </label>
                <input type="file" name="" id="formFile" style={{ display: "none" }} accept='image/*' onChange={(e) => setVignette(e.target.files[0])} />
                {vignette && (
                    <>
                        <hr />
                        <FormLabel component="legend">Preview</FormLabel>
                        <div className="shareImgContainer">
                            <img className="shareImgUp" src={URL.createObjectURL(vignette)} alt="" />
                            <Cancel className="shareCancelImg" onClick={() => setVignette(null)} />
                        </div>
                        <Button label='Upload Picture' icon='pi pi-fw pi-send' onClick={HandleUpload} />
                        <hr />
                    </>
                )}
                {props.src && (
                    <>
                        <hr />
                        <FormLabel component="legend">Actual Image</FormLabel>
                        <div className="shareImgContainer">
                            <img className="shareImgUp" src={props.src} alt="" />
                        </div>
                        <hr />
                    </>
                )}
            </FormControl>
            <FormControl component="fieldset">
                <FormLabel component="legend">Object fit</FormLabel>
                <RadioGroup defaultValue={props.objectFit} onChange={(e) => setProp(props => props.objectFit = e.target.value)}>
                    <FormControlLabel label="Initial" value="" control={<Radio size="small" color="primary" />} />
                    <FormControlLabel label="Cover" value="cover" control={<Radio size="small" color="primary" />} />
                    <FormControlLabel label="Contain" value="contain" control={<Radio size="small" color="primary" />} />
                </RadioGroup>
            </FormControl>
            <FormControl fullWidth={true} margin="normal" component="fieldset">
                <FormLabel component="legend">Height</FormLabel>
                <InputNumber value={props.height} onChange={(e) => setProp(props => props.height = e.value)} />
            </FormControl>
            <FormControl fullWidth={true} margin="normal" component="fieldset">
                <FormLabel component="legend">Width</FormLabel>
                <InputNumber value={props.width} onChange={(e) => setProp(props => props.width = e.value)} />
            </FormControl>
            <FormControl fullWidth margin="normal" component="fieldset">
                <FormLabel component="legend">Border Radius</FormLabel>
                <InputNumber value={props.borderRadius} onChange={(e) => { setProp(props => props.borderRadius = e.value) }} />
            </FormControl>
        </>
    )
}

Image.craft = {
    props: {
        src: "",
        width: '',
        height: ''
    },
    related: {
        settings: ImageSettings
    }
}