import { signInWithEmailAndPassword } from 'firebase/auth'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { AuthContext } from '../context/auth'
import { auth } from '../firebase'

function Login() {
  const [data, setData] = useState({
    email: '',
    password: '',
    error: null,
    loading: false,
  })
  const history = useHistory()
  const { email, password, error, loading } = data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  };

  const SignAsGuest = async (e) => {
    e.preventDefault();
    try {
      setData({ ...data, error: null, loading: true })
      await signInWithEmailAndPassword(auth, 'Guest@guest.com', 'Guest1234');
      setData({ ...data, error: null, loading: false })
      history.push("/cms");
    } catch (err) {
      console.log(err)
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true })
    if (!email || !password) {
      setData({ ...data, error: "All fields are required" })
    }
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setData({
        email: '',
        password: '',
        error: null,
        loading: false,
      })
      history.push("/cms");
    } catch (err) {
      setData({ ...data, error: err.message, loading: false })
      console.log(err)
    }

  }
  return (
    <div className="grid container login">
      <div className="surface-card p-4 shadow-2 border-round loginContainer">
        <div className="text-center mb-5" style={{ flex: '4' }}>
          <img src="images/blocks/logos/hyper.svg" alt="hyper" height="50" className="mb-3" />
          <div className="text-900 text-3xl font-medium mb-3">Welcome To HUBCOM CMS</div>
        </div>

        <form style={{ flex: '6' }} onSubmit={handleSubmit}>
          <label htmlFor="email1" className="block text-900 font-medium mb-2">Email</label>
          <InputText id="email1" type="email" name='email' onChange={handleChange} value={email} className="w-full mb-3" />

          <label htmlFor="password1" className="block text-900 font-medium mb-2">Password</label>
          <InputText id="password1" type="password" name='password' onChange={handleChange} value={password} className="w-full mb-3" />
          <Button label={loading ? 'Loggin in ...' : 'Login'} style={{ marginTop: '35px' }} icon="pi pi-user" className="w-full mb-3" />
          <Button label={loading ? 'Loggin in ...' : 'Sign-in As Guest'} icon="pi pi-user" className="w-full mb-3" onClick={SignAsGuest} />
          <Link to='/register'>
            <Button label="Register" icon="pi pi-user" className="w-full" />
          </Link>
        </form>

      </div>
    </div>
  )
}

export default Login