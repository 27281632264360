import React from 'react';
import { Toolbox } from './components/Toolbox'
import classNames from 'classnames';
import { Button } from "primereact/button";

export const ToolbarBuild = ({ active, setActive }) => {
    
    const toggleConfigurator = (event) => {
        setActive(!active);
    }

    const configClassName = classNames('toolbar-config', {
        'toolbar-config-active': active
    })
    return (
        <div className={configClassName} id={"toolbar-config"}>
            <button className="toolbar-config-button p-link" id="toolbar-config-button" onClick={toggleConfigurator}>
                <i className="pi pi-prime"></i>
            </button>
            <Button className="p-button-danger toolbar-config-close p-button-rounded p-button-text" icon="pi pi-times" onClick={e => setActive(false)} />
            <div className="layout-config-content"style={{ overflow:"auto",height: '90vh' }}>
                <Toolbox />
            </div>
        </div>
    );
}
