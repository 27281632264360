export const menuItems = [
  {
    title: "Home",
    to: '#/preview?title=Homepage'
  },
  {
    title: "TruvialFit",
    to: '#/preview?title=Truviafit',
  },
  {
    title: "About",
    to:"#/preview?title=Alimentation",
  },
];