import React from 'react'
import ReactLoading from "react-loading";

function Loading() {
  return (
    <div style={{position:'relative', display:'flex'}}>
        <h2 style={{position:'fixed', top:'50%', left:'50%', transform: 'translate(-50%, -50%)', display:"flex", alignItems:'center'}}>Loading <ReactLoading type="bars" color="white"
        height={60} width={70}/></h2>
    </div>
  )
}

export default Loading