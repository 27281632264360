import { useNode } from '@craftjs/core';
import React, { useEffect, useState } from 'react'
import { Galleria } from 'primereact/galleria';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { PhotoService } from '../../service/PhotoService';

export const Galerie = ({ images }) => {
    const [defaultImages, setDefaultImages] = useState([])
    const galleriaResponsiveOptions = [
        {
            breakpoint: "1024px",
            numVisible: 5,
        },
        {
            breakpoint: "960px",
            numVisible: 4,
        },
        {
            breakpoint: "768px",
            numVisible: 3,
        },
        {
            breakpoint: "560px",
            numVisible: 1,
        },
    ]
    useEffect(() => {
        const photoService = new PhotoService();
        photoService.getImages().then((images) => setDefaultImages(images));
    }, []);
    const { connectors: { connect, drag } } = useNode();
    const galleriaItemTemplate = (item) => <img src={`${images ? item.itemImageSrc : 'assets/'+item.itemImageSrc}`} alt={item.alt} style={{ width: '100%', display: 'block' }} />
    const galleriaThumbnailTemplate = (item) => <img src={`${images ? item.thumbnailImageSrc : 'assets/'+item.thumbnailImageSrc}`} alt={item.alt} style={{ width: '80px', display: 'block' }} />

    return (
        <div ref={ref => connect(drag(ref))}>
            <Galleria value={images || defaultImages} responsiveOptions={galleriaResponsiveOptions} numVisible={7} circular style={{ maxWidth: '800px', margin: 'auto' }}
                item={galleriaItemTemplate} thumbnail={galleriaThumbnailTemplate}></Galleria>
        </div>
    )
}
const GalerieSettings = () => {
    const { actions: { setProp }, props } = useNode((node) => ({
        props: node.data.props
    }));
    const handleChange = (e) => {
        let images = []
        for (let i = 0; i < e.target.files.length; i++) {
          const newImage = e.target.files[i];
          const url = URL.createObjectURL(newImage)
          images.push({
            itemImageSrc: url,
            thumbnailImageSrc: url,
            alt: "Description for Image 1",
            title: "Title 1"
          })          
        }
        setProp(props => props.images = images)
      };
      

    return (
        <>
            <label htmlFor="formFile" style={{ marginTop: "10px", cursor: "pointer" }}>
                <AddAPhotoIcon fontSize="large" />
            </label>
            <input type="file" name="" multiple id="formFile" style={{ display: "none" }} accept='image/*' onChange={handleChange} />
        </>
    )
}

Galerie.craft = {
    props: {
        src: "",
        width: '',
        height: ''
    },
    related: {
        settings: GalerieSettings
    }
}