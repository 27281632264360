import { useNode } from '@craftjs/core';
import { FormControl, FormLabel, InputLabel, MenuItem, Select } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react'

export const RichText = ({ srcDoc, fontFamily, color }) => {
    const [id, setId] = useState('')
    const { connectors: { connect, drag } } = useNode();
    useEffect(() => {
        const makeid = () => {
            var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

            for (var i = 0; i < 5; i++)
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            return text

        }
        setId(makeid())
    }, [])
    const css = (`
  #${id}{ 
    font-family: ${fontFamily};
    color: ${color};
  } 
  `)
    return (
        <>
            <div
                id={id}
                ref={ref => connect(drag(ref))}
                dangerouslySetInnerHTML={{ __html: srcDoc }}
            />
            <style lang="css">
                {css}
            </style>
        </>
    )
}
const RichTextSettings = () => {
    const editorRef = useRef(null);
    const [data, setData] = useState('')
    const { actions: { setProp }, props } = useNode((node) => ({
        props: node.data.props
    }));

    return (
        <>
            <FormControl component="fieldset" fullWidth margin="normal">
                <InputLabel id="demo-simple-select-label">Font Family</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={props.fontFamily}
                    label="Type"
                    onChange={(e) => setProp(props => props.fontFamily = e.target.value)}
                >
                    <MenuItem value="'Roboto', sans-serif">Roboto</MenuItem>
                    <MenuItem value="'League Gothic', sans-serif">League Gothic</MenuItem>
                    <MenuItem value="'Poppins', sans-serif">Poppins</MenuItem>
                    <MenuItem value="'Staatliches', cursive">Staatliches</MenuItem>
                    <MenuItem value="'Smooch', cursive">Smooch</MenuItem>
                    <MenuItem value="'Fredoka', sans-serif">Fredoka</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth={true} margin="normal" component="fieldset">
                <FormLabel component="legend">Text Color</FormLabel>
                <InputText value={props.color || '#fff'} onChange={e => { setProp(props => props.color = e.target.value) }} />
            </FormControl>
            <Editor
                apiKey={process.env.REACT_APP_EDITOR_API_KEY}
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={props.srcDoc}
                onEditorChange={(newValue, editor) => setData(newValue)}
                init={{
                    selector: 'textarea',
                    height: 300,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'charmap',
                        'anchor', 'insertdatetime', 'wordcount',
                    ],
                    toolbar: 'undo redo | blocks ' +
                        'bold italic underline forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat| link',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
            <Button label='Save Data' className='mb-2 p-button-success' onClick={(e) => setProp(props => props.srcDoc = data)} icon='pi pi-fw pi-save' />
        </>
    )
}
RichText.craft = {
    related: {
        settings: RichTextSettings
    }
}