import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { Button } from "primereact/button";
import { Element, useEditor } from "@craftjs/core";
import { Text } from "./user/Text";
import { MaterialButton } from "./user/MaterialButton";
import { Card } from "./user/Card";
import { Container } from "./user/Container";
import { Image } from "./user/Image";
import { Galerie } from "./user/Galerie";
import { Panels } from "./user/Panel";
import { Tags } from "./user/Tags";
import { CarouselUser } from "./user/Carousel";
import { RichText } from "./user/RichText";
import { YTVideo } from "./user/YTVideo";

export const Toolbox = () => {
  const { connectors, query } = useEditor();
  return (
    <Box px={2} py={2}>
      <Grid container direction="column" alignItems="center" justify="center" spacing={1}>
        <Box pb={2}>
          <Typography>Drag to add</Typography>
        </Box>
        <Grid container direction="row" justifyContent='space-evenly' item>
          <Button className="toolboxButton" icon=' pi pi-fw pi-circle-fill' ref={ref => connectors.create(ref, <MaterialButton label='Click me' />)} label='Button' />
          <Button className="toolboxButton" icon='pi pi-fw pi-tag' ref={ref => connectors.create(ref, <Tags value='tags' />)} label='Tags' />
        </Grid>
        <Grid container direction="row" justifyContent='space-evenly' item>
          <Button className="toolboxButton" ref={ref => connectors.create(ref, <Image />)} icon='pi pi-fw pi-image' label='Image' />
          <Button className="toolboxButton" ref={ref => connectors.create(ref, <Panels />)} icon='pi pi-fw pi-credit-card' label='Accordion' />
        </Grid>
        <Grid container direction="row" justifyContent='space-evenly' item>
          <Button className="toolboxButton" icon='pi pi-fw pi-align-justify' ref={ref => connectors.create(ref, <Text text="Hi world" />)} label='Text' />
          <Button className="toolboxButton" icon='pi pi-fw pi-align-center' ref={ref => connectors.create(ref, <RichText srcDoc={'<p>This is Rich text sample</p>'} />)} label='Rich text' />
        </Grid>
        <Grid container direction="row" justifyContent='space-evenly' item>
          <Button className="toolboxButton" icon='pi pi-fw pi-credit-card' ref={ref => connectors.create(ref, <Card />)} label='Card' />
          <Button className="toolboxButton" icon='pi pi-fw pi-external-link' ref={ref => connectors.create(ref, <Element is={Container} paddingBottom={30} paddingTop={30} paddingLeft={30} paddingRight={30} canvas />)} label='Container' />
        </Grid>        
        <Grid container direction="row" justifyContent='space-evenly' item>
          <Button className="toolboxButton" icon='pi pi-fw pi-clone' ref={ref => connectors.create(ref, <CarouselUser />)} label='Carousel' />
          <Button className="toolboxButton" icon='pi pi-fw pi-youtube' ref={ref => connectors.create(ref, <YTVideo />)} label='Youtube Video' />
        </Grid>
      </Grid>
    </Box>
  )
};