import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { AuthContext } from './context/auth';
import { useHistory } from 'react-router-dom';
import { auth } from './firebase';
import { signOut } from 'firebase/auth';

export const AppTopbar = (props) => {
    const { user } = useContext(AuthContext)
    const history = useHistory()
    const handleLogOut = async () =>{
        await signOut(auth);
        history.push("/login");
    }
    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
                <img 
                src='assets/layout/images/hubcom-logo.png' 
                alt="logo" 
                style={{background: 'black',width:'100px', height: '72px',objectFit: 'contain',borderRadius: '28px'
                }}
                />
                <span>HUBCOM CMS</span>
            </Link>

            {user && <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>}

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", { 'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                {user && <li>
                    <button className="p-link layout-topbar-button" onClick={handleLogOut} >
                        <i className="pi pi-power-off" style={{ marginRight:'10px' }} />
                        <span style={{ marginRight:'10px' }} >LogOut</span>
                    </button>
                </li>}
                <li>
                    <div style={{ display:'flex', justifyContent:'center', marginTop:'15px'}}>
                        <>{user?.email}</>
                    </div>
                </li>
            </ul>
        </div>
    );
}
