import React from 'react';
import { Button } from 'primereact/button';


const Dashboard = () => {

    return (
        <>
            <div className="grid grid-nogutter surface-section text-800">
                <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
                    <section>
                        <span className="block text-6xl font-bold mb-1">Welcome To</span>
                        <div className="text-6xl text-primary font-bold mb-3">HUBCOM CMS</div>
                        <p className="mt-0 mb-4 text-700 line-height-3">Here you can modify, add or delete content from your website pages <br />
                            Note that you can't create pages but only modify the content of the existing one (for now) </p>
                    </section>
                </div>
                <div className="col-12 md:col-6 overflow-hidden">
                    <img src="images/blocks/hero/hero-1.png" alt="hero-1" className="md:ml-auto block md:h-full" style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)' }} />
                </div>
            </div>
            <div className="surface-section px-4 py-8 md:px-6 lg:px-8 text-center">
                <div className="mb-3 font-bold text-2xl">
                    <span className="text-900">One Product, </span>
                    <span className="text-blue-600">Many Possibilities</span>
                </div>
                <div className="text-700 text-sm mb-6">Control your website content with ease</div>
            </div>
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return (prevProps.location.pathname === nextProps.location.pathname) && (prevProps.colorMode === nextProps.colorMode);
};

export default React.memo(Dashboard, comparisonFn);
