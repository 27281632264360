import { Cancel } from '@mui/icons-material'
import { TextField } from '@mui/material'
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { Button } from 'primereact/button';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { Toast } from 'primereact/toast';


function AddCategorie() {
    const [vignette, setVignette] = useState([])
    const [name, setName] = useState('')
    const [tag, setTag] = useState('')
    const toast = useRef(null)
    const [loading, setLoading] = useState(false)

    const NameChange = (e) => {
        setName(e.target.value)
        setTag(e.target.value)
    }
    const handleChange = (e) => {
        let images = []
        for (let i = 0; i < e.target.files.length; i++) {
            const newImage = e.target.files[i];
            const url = URL.createObjectURL(newImage)
            images.push({
                src: url,
                imageData: newImage
            })

        }
        setVignette([...vignette, ...images]);
    }
    const deleteFile = (e) => {
        const s = vignette.filter((item, index) => index !== e);
        setVignette(s);
    }
    const TagChange = (e) => {
        setTag(e.target.value)
    }
    const SaveCategorie = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            let url = []
            for (let index = 0; index < vignette.length; index++) {
                const image = vignette[index];
                const fileRef = ref(storage, `categorie/${new Date().getTime()} - ${image.imageData.name}`);
                const snap = await uploadBytes(fileRef, image.imageData);
                const dlUrl = await getDownloadURL(ref(storage, snap.ref.fullPath))
                url.push(dlUrl)
            }
            await addDoc(collection(db, 'ProductCategorie'), {
                name,
                tag,
                url,
                createdAt: Timestamp.fromDate(new Date()),
            })
            setLoading(false)
            toast.current.show({ severity: 'success', summary: 'Confirmation', detail: 'Categorie Added successfully', life: 3000 });
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Toast ref={toast} />
            <div className='card' style={{ background: 'transparent' }}><Link to={'/cms/Categorie'} style={{ color: 'black', fontWeight: 'bold' }}><i className='pi pi-fw pi-arrow-left'></i> Categories</Link></div>
            <div className='card' style={{ background: 'transparent' }}><h3 style={{ fontWeight: 'bold' }}>Add Categorie</h3></div>
            <form onSubmit={SaveCategorie}>
                <div className='card'>
                    <h4>Details</h4>
                    <div className='grid' style={{ width: '100%', justifyContent: 'center' }}>
                        <TextField margin='normal' onChange={NameChange} variant='outlined' style={{ width: '50%' }} label='Name' />
                        <TextField margin='normal' value={tag} onChange={TagChange} variant='outlined' style={{ width: '50%' }} label='Permalink' />
                    </div>
                    <TextField
                        fullWidth
                        id="outlined-multiline-static"
                        label="Description"
                        multiline
                        rows={4}
                    />
                </div>
                <div className='card'>
                    <h4>References Images</h4>
                    <label htmlFor="formFile" style={{ marginTop: "10px", cursor: "pointer" }}>
                        <AddAPhotoIcon fontSize="large" />
                    </label>
                    <input type="file" name="" multiple id="formFile" style={{ display: "none" }} accept='image/*' onChange={handleChange} />
                    {vignette.length > 0 && (
                        <>
                            <hr />
                            <div className="grid">
                                {vignette.length > 0 && vignette.map((item, index) =>
                                    <div key={item.src} className="shareImgContainer" style={{ width: '25%' }}>
                                        <img className="shareImgUp" src={item.src} />
                                        <Cancel className="shareCancelImg" onClick={() => deleteFile(index)} />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
                <Button disabled={loading} label={loading ? 'Saving...' : 'Save'} icon='pi pi-fw pi-save' type='submit' className='p-button-success' />
            </form>
        </>
    )
}

export default AddCategorie