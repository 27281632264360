import React, { useEffect, useState } from "react";
import { useNode } from "@craftjs/core";
import ContentEditable from 'react-contenteditable'
import { Slider, FormControl, FormLabel, MenuItem, Select, InputLabel } from "@mui/material";
import { RadioGroup, Radio, FormControlLabel } from "@mui/material";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Panel } from "primereact/panel";

export const Text = ({
  text,
  fontSize,
  fontSizeM,
  fontSizeT,
  fontWeight,
  color,
  fontStyle,
  tagName,
  fontFamily
}) => {
  const [id, setId] = useState('')
  const { connectors: { connect, drag } } = useNode();
  useEffect(() => {
    const makeid = () => {
      var text = "";
      var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

      for (var i = 0; i < 5; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text

    }
    setId(makeid())
  }, [])
  const css = (`
  #${id} ${tagName || 'p'}{
    font-size: ${fontSize}px !important;
    font-weight: ${fontWeight};
    font-style: ${fontStyle}; 
    color: ${color};
    font-family: ${fontFamily}
  } 
  @media screen and (max-width: 770px){
    #${id} ${tagName || 'p'}{
      font-size: ${fontSizeT}px !important;
    }   
  }
  @media screen and (max-width:450px){
    #${id} ${tagName || 'p'}{
      font-size: ${fontSizeM}px !important;
    }
  }
  `)
  return (
    <div id={id} ref={ref => connect(drag(ref))}>
      <ContentEditable
        disabled
        html={text}
        tagName={tagName || 'p'}
      />
      <style lang="css">
        {css}
      </style>
    </div>

  )
}
const TextSettings = () => {
  const { actions: { setProp }, props } = useNode((node) => ({
    props: node.data.props
  }));

  return (
    <>
      <Panel header='General Settings' toggleable>
        <FormControl component="fieldset" className="mb-3">
          <FormLabel component="legend">Text</FormLabel>
          <InputTextarea autoResize id="" type="text" value={props.text || ''} onChange={(e) => setProp(props => props.text = e.target.value)} />
        </FormControl>
        <FormControl fullWidth={true} margin="normal" component="fieldset">
          <FormLabel component="legend">Text Color</FormLabel>
          <InputText value={props.color || '#fff'} onChange={e => { setProp(props => props.color = e.target.value) }} />
        </FormControl>
        <FormControl component="fieldset" fullWidth margin="normal">
          <InputLabel id="demo-simple-select-label">Font Family</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.fontFamily}
            label="Type"
            onChange={(e) => setProp(props => props.fontFamily = e.target.value)}
          >
            <MenuItem value="'Roboto', sans-serif">Roboto</MenuItem>
            <MenuItem value="'League Gothic', sans-serif">League Gothic</MenuItem>
            <MenuItem value="'Poppins', sans-serif">Poppins</MenuItem>
            <MenuItem value="'Staatliches', cursive">Staatliches</MenuItem>
            <MenuItem value="'Smooch', cursive">Smooch</MenuItem>
            <MenuItem value="'Fredoka', sans-serif">Fredoka</MenuItem>
          </Select>
        </FormControl>
        <FormControl component="fieldset" fullWidth margin="normal">
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.tagName}
            label="Type"
            onChange={(e) => setProp(props => props.tagName = e.target.value)}
          >
            <MenuItem value="h1">H1</MenuItem>
            <MenuItem value="h2">H2</MenuItem>
            <MenuItem value="h3">H3</MenuItem>
            <MenuItem value="h4">H4</MenuItem>
            <MenuItem value="h5">H5</MenuItem>
            <MenuItem value="p">Paragraph</MenuItem>
          </Select>
        </FormControl>
        <FormControl component="fieldset" fullWidth margin="normal">
          <InputLabel id="demo-simple-select-label">Font Weight</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.fontWeight}
            label="Font weight"
            onChange={(e) => setProp(props => props.fontWeight = e.target.value)}
          >
            <MenuItem value="bold">Bold</MenuItem>
            <MenuItem value="100">100</MenuItem>
            <MenuItem value="300">300</MenuItem>
            <MenuItem value="400">400</MenuItem>
            <MenuItem value="500">500</MenuItem>
            <MenuItem value="700">700</MenuItem>
            <MenuItem value="900">900</MenuItem>
          </Select>
        </FormControl>
        <FormControl component="fieldset">
          <FormLabel component="legend">Font Style</FormLabel>
          <RadioGroup defaultValue={props.fontStyle} onChange={(e) => setProp(props => props.fontStyle = e.target.value)}>
            <FormControlLabel label="Italic" value="italic" control={<Radio size="small" color="primary" />} />
            <FormControlLabel label="Horizontal" value="" control={<Radio size="small" color="primary" />} />
          </RadioGroup>
        </FormControl>
      </Panel>
      <Panel toggleable header='Desktop'>
        <FormControl size="small" component="fieldset">
          <FormLabel component="legend">Font size</FormLabel>
          <InputNumber value={props.fontSize} onChange={(e) => { setProp(props => props.fontSize = e.value) }} />
        </FormControl>

      </Panel>
      <Panel toggleable header='Tablet'>
        <FormControl size="small" component="fieldset">
          <FormLabel component="legend">Font size</FormLabel>
          <InputNumber value={props.fontSizeT} onChange={(e) => { setProp(props => props.fontSizeT = e.value) }} />
        </FormControl>
      </Panel>
      <Panel toggleable header='Mobile'>
        <FormControl size="small" component="fieldset">
          <FormLabel component="legend">Font size</FormLabel>
          <InputNumber value={props.fontSizeM} onChange={(e) => { setProp(props => props.fontSizeM = e.value) }} />
        </FormControl>
      </Panel>
    </>
  )
}

Text.craft = {
  props: {
    text: "Hi",
    fontSize: '',
    fontWeight: "",
    fontStyle: "",
    color: "fff"
  },
  related: {
    settings: TextSettings
  }
}