import React, { useState, useEffect, useRef, useContext } from 'react';
import classNames from 'classnames';
import { Route, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppConfig } from './AppConfig';

import Dashboard from './components/Dashboard';
import EmptyPage from './pages/EmptyPage';
import MenuBluilder from './pages/MenuBluilder';

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';
import { AuthContext } from './context/auth';
import PageBuilder from './pages/PageBuilder';
import Products from './pages/Ecommerce/Products';
import AddProduct from './pages/Ecommerce/AddProduct';
import AddCategorie from './pages/Ecommerce/AddCategorie';
import Categories from './pages/Ecommerce/Categories';
import BlogBuilder from './pages/BlogBuilder';


const App = () => {
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    // const menu = [
    //     {
    //         label: 'Home',
    //         items: [{
    //             label: 'Introduction', icon: 'pi pi-fw pi-home', to: '/cms'
    //         }]
    //     },
    //     {
    //         label: 'Builder', icon: 'pi pi-fw pi-clone',
    //         items: [
    //             { label: 'Developer page', icon: 'pi pi-fw pi-prime', to: '/cms/empty' },
    //             { label: 'Menu Builder', icon: 'pi pi-fw pi-bars', to: '/cms/menubuilder' },
    //             { label: 'Page & Blog Builder', icon: 'pi pi-fw pi-prime',
    //                 items:[
    //                     {label: 'Page Builder', icon: 'pi pi-fw pi-server', to:'/cms/PageBuilder'},
    //                     {label: 'Blog Builder', icon: 'pi pi-fw pi-file', to:'/cms/BlogBuilder'}
    //                 ]  },
    //             { label: 'E-commerce', icon: 'pi pi-fw pi-shopping-cart',
    //                 items:[
    //                     {label: 'Orders', icon:'pi pi-fw pi-list'},
    //                     {label: 'Products', icon:'pi pi-fw pi-briefcase',
    //                         items:[
    //                             {label:'Products', to: '/cms/Products'},
    //                             {label:'Categories', to: '/cms/Categorie'}
    //                         ]
    //                     },
    //                     {label:'Customers', icon:'pi pi-fw pi-users'}
    //                 ]
    //             }
    //         ]
    //     }
    // ];
    const menu = [
        {
            label: 'Home',
            items: [{
                label: 'Introduction', icon: 'pi pi-fw pi-home', to: '/cms'
            }]
        },
        {
            label: 'Builder', icon: 'pi pi-fw pi-clone',
            items: [
                { label: 'Page & Blog Builder', icon: 'pi pi-fw pi-prime',
                    items:[
                        {label: 'Page Builder', icon: 'pi pi-fw pi-server', to:'/cms/PageBuilder'},
                        {label: 'Blog Builder', icon: 'pi pi-fw pi-file', to:'/cms/BlogBuilder'}
                    ]  }
            ]
        }
    ];

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });
    const { user } = useContext(AuthContext)

    return (
        <>
            <div className={wrapperClass} onClick={onWrapperClick}>
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                    mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

               <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                </div>

               <div className="layout-main-container">
                    <div className="layout-main">
                        <Route path="/cms" exact render={() => <Dashboard colorMode={layoutColorMode} location={location} />} />
                        <Route path='/cms/PageBuilder' component={PageBuilder} />
                        <Route path="/cms/empty" component={EmptyPage} />
                        <Route path="/cms/menubuilder" component={MenuBluilder} />
                        <Route path="/cms/Products" component={Products} />
                        <Route path="/cms/Add" component={AddProduct} />
                        <Route path="/cms/AddCategorie" component={AddCategorie} />
                        <Route path="/cms/Categorie" component={Categories} />
                        <Route path="/cms/BlogBuilder" component={BlogBuilder} />
                    </div>

                    <AppFooter layoutColorMode={layoutColorMode} />
                </div>

                <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                    layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

                <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                    <div className="layout-mask p-component-overlay"></div>
                </CSSTransition>

            </div>
        </>
    );

}

export default App;
