import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
//import * as serviceWorker from './serviceWorker';
import { HashRouter, Route } from 'react-router-dom'
import ScrollToTop from './ScrollToTop';
import AuthProvider from './context/auth';
import Login from './pages/Login';
import Register from './pages/Register';
import LoginBlock from './LoginBlock';
import Preview from './pages/Preview';

ReactDOM.render(
    <AuthProvider>
        <HashRouter>
            <ScrollToTop>
                <Route path='/preview' exact component={Preview} />
                <Route path='/login' exact component={Login} />
                <Route path='/register' exact component={Register} />
                <Route path='/cms'  render={() => <LoginBlock />} />
            </ScrollToTop>
        </HashRouter>
    </AuthProvider>,
    document.getElementById('root')
);
