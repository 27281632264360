import {useState, createContext, useEffect}  from 'react'
import {onAuthStateChanged} from 'firebase/auth'
import React from 'react'
import { auth, db } from '../firebase';
import Loading from '../components/Loading';
import { doc, getDoc } from 'firebase/firestore';
import Load from '../components/Load';


export const AuthContext = createContext();

    const AuthProvider = ({children}) =>{
        const [user, setUser] = useState(null);
        const [userData, setUserData] = useState(null);
        const [loading, setLoading] = useState(true);
        const [load, setLoad]=useState(true)
        
        useEffect(()=>{
             onAuthStateChanged(auth, (user) =>{
                setUser(user);
                setLoading(false)
                if (auth.currentUser===null) {
                    setLoad(false)
                }
                
             })            
        },[]);
        
        useEffect(()=>{
            if (!user) return;
                getDoc(doc(db, 'users', user.uid)).then((docSnap) =>{
                    if (docSnap.exists) {
                         setUserData(docSnap.data());
                    };
                   setLoad(false)
                  })
        },[user])
        if(loading){
            return <Loading />
        }
        if(load){
            return <Load />
        }
        return <AuthContext.Provider value={{user, userData}}>
            {children}
        </AuthContext.Provider>
    };
export default AuthProvider;