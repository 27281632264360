import React, { useEffect, useRef, useState } from 'react'
import { Container } from '../components/user/Container';
import { MaterialButton } from '../components/user/MaterialButton';
import { Image } from '../components/user/Image';
import { Card } from '../components/user/Card';
import { Text } from '../components/user/Text';
import { Galerie } from '../components/user/Galerie';
import { Fieldset } from 'primereact/fieldset'
import { Panels } from '../components/user/Panel'
import { Tags } from '../components/user/Tags'
import { CarouselUser, CarouselItem } from '../components/user/Carousel'
import { Editor, Frame, Element } from "@craftjs/core";
import { ToolbarBuild } from '../ToolbarBuild';
import { ControlBar } from '../components/ControlBar';
import HeaderBlock from '../components/HeaderBlock';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { auth, db } from '../firebase';
import 'draft-js/dist/Draft.css';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { RichText } from '../components/user/RichText';
import { Link } from 'react-router-dom';
import { SettingBuild } from '../SettingBuild';
import { YTVideo } from '../components/user/YTVideo';

function BlogBuilder() {
  let emptyPage = {
    name: '',
    description: '',
    category: null
  }
  const [active, setActive] = useState(false);
  const [actives, setActives] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(null);
  const [dropdownValues, setDropdownValues] = useState([]);
  const [page, setPage] = useState(emptyPage);
  const ref = useRef(null)
  const html = ref.current?.firstChild.firstChild?.outerHTML
  useEffect(() => {
    const contentRef = collection(db, 'PageTemplate');
    const q = query(contentRef, where('categorie','==','Blog'), where('createdBy','==',`${auth.currentUser.uid}`))
    onSnapshot(q, querySnapshot => {
      setDropdownValues(querySnapshot.docs.map((doc) => ({
        data: doc.data(),
        id: doc.id,
        label: doc.data().title
      }
      )));
    })
  }, [])
  return (
    <>
      <Editor resolver={{ YTVideo,CarouselItem,RichText,CarouselUser,Card, MaterialButton, Text, Container, Tags, Panels, Galerie, Image }}>
        <HeaderBlock
          page={page}
          dropdownValue={dropdownValue}
          dropdownValues={dropdownValues}
          setPage={setPage}
          emptyPage={emptyPage}
          setDropdownValue={setDropdownValue}
          setDropdownValues={setDropdownValues}
        />
        <Fieldset legend='Blog Creator'>
          <div className='grid' ref={ref} >
            <div className='col:md-8' style={{ flex: '8', marginRight: '10px' }} >
              <Frame>
                <Element is={Container} canvas>
                <Text text="modify your blog here! select a blog in the builder to begin with" fontFamily={"'Staatliches', cursive"} fontSize={58} />
                </Element>
              </Frame>
            </div>
            <ToolbarBuild active={active} setActive={setActive} />
            <SettingBuild active={actives} setActive={setActives} />
          </div>
        </Fieldset>
        <ControlBar page={page} html={html} dropdownValue={dropdownValue} />
      </Editor>
      <div className="card" >
        <h5>Live preview</h5>
        <Link target='_blank' to={`/preview?title=${dropdownValue?.data?.title}`}><Button label="Show" icon="pi pi-external-link" className='p-button-info' /> </Link>
      </div>
    </>
  )
}

export default BlogBuilder;