import { useEditor } from '@craftjs/core'
import classNames from 'classnames'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Panel } from 'primereact/panel'
import { RadioButton } from 'primereact/radiobutton'
import React, { useState } from 'react'
import lz from "lzutf8";

export default function HeaderBlock({ page, setPage, emptyPage, setDropdownValue, setDropdownValues, dropdownValue, dropdownValues }) {
    const [productDialog, setProductDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const { actions } = useEditor();

    const openNew = () => {
        setPage(page || emptyPage);
        setSubmitted(false);
        setProductDialog(true);
    }
    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }
    const onCategoryChange = (e) => {
        let _page = { ...page };
        _page['category'] = e.value;
        setPage(_page);
    }
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _page = { ...page };
        _page[`${name}`] = val;

        setPage(_page);
    }
    const savePage = () => {
        setSubmitted(true);
        setProductDialog(false)
    }
    const selected = (e) => {
        setDropdownValue(e.value)
        setPage({
            name: e.value.data.title,
            description: e.value.data.description,
            category: e.value.data.categorie
        })
        const json = lz.decompress(lz.decodeBase64(e.value.data.content));
        actions.deserialize(json);
    }
    const pageDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={savePage} />
        </>
    );
    return (
        <>
            <div className='card' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5>Builder</h5>
                <Dropdown
                    value={dropdownValue}
                    onChange={selected}
                    options={dropdownValues}
                    optionLabel='label'
                    placeholder="Select"
                />
                <Button
                    label={dropdownValue ? 'Change page value':'Create new Page'}
                    className={"mr-2"}
                    onClick={openNew}
                />
            </div>
            <Panel header='Page detail' toggleable className='mb-2'>
                <Dialog visible={productDialog} blockScroll={true} style={{ width: '450px' }} header="Pages Details" modal className="p-fluid" footer={pageDialogFooter} onHide={hideDialog}>
                    <div className="field">
                        <label htmlFor="name">Name</label>
                        <InputText id="name" value={page.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !page.name })} />
                        {submitted && !page.name && <small className="p-invalid">Name is required.</small>}
                    </div>
                    <div className="field">
                        <label htmlFor="description">Meta-description</label>
                        <InputTextarea id="description" value={page.description} autoResize onChange={(e) => onInputChange(e, 'description')} required rows={3} cols={20} />
                    </div>

                    <div className="field">
                        <label className="mb-3">Category</label>
                        <div className="formgrid grid">
                            <div className="field-radiobutton col-6">
                                <RadioButton inputId="category1" name="category" value="Pages" onChange={onCategoryChange} checked={page.category === 'Pages'} />
                                <label htmlFor="category1">Pages</label>
                            </div>
                            <div className="field-radiobutton col-6">
                                <RadioButton inputId="category2" name="category" value="Blog" onChange={onCategoryChange} checked={page.category === 'Blog'} />
                                <label htmlFor="category2">Blog</label>
                            </div>
                            <div className="field-radiobutton col-6">
                                <RadioButton inputId="category3" name="category" value="Landing Pages" onChange={onCategoryChange} checked={page.category === 'Landing Pages'} />
                                <label htmlFor="category3">Landing Pages</label>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <div>
                    <h5>Page Title :</h5> '{page.name || 'no page title'}'
                    <h5>Description :</h5> '{page.description || 'no page description'}'
                    <h5>Categorie :</h5> '{page.category || 'no page categorie'}'
                </div>
            </Panel>
        </>
    )
}
