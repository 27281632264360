import Navbar from "./Navbar";
import React from 'react'
import { useNode } from "@craftjs/core";

const App = () => {
  return (
    <header>
      <div className="nav-area">
        <a href="/#" className="logo">
          Logo
        </a>
        <Navbar />
      </div>
    </header>
  );
};

export default App;