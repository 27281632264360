import { collection, onSnapshot, query } from 'firebase/firestore'
import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { db } from '../../firebase'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column'

function Categories() {
    const [categorie, setCategorie] = useState([])

    useEffect(() => {
        const contentRef = collection(db, 'ProductCategorie');
        const q = query(contentRef)
        onSnapshot(q, querySnapshot => {
            setCategorie(querySnapshot.docs.map((doc) => ({
                data: doc.data(),
                id: doc.id
            }
            )));
        })
    }, [])    
    const nameBodyField =(rowData) =>{
        return <p><b>{rowData.data?.name}</b></p>
    }
    const tagBodyField =(rowData) =>{
        return <p><b>{rowData.data?.tag}</b></p>
    }
    const imageBodyTemplate = (rowData) => {
        return rowData.data?.url?.map((image) =><img style={{ width:'135px' }} src={image} className="product-image" /> )   
    }

    return (
        <>
            <div className='card productHeader'>
                <h3><b>Categories</b></h3>
                <div><Link to={"/cms/AddCategorie"}><Button label='Add' icon='pi pi-fw pi-plus' /></Link></div>
            </div>
            <div className='card'>
                <DataTable value={categorie} >
                    <Column  header="Name" body={nameBodyField}></Column>
                    <Column  header="Tag Name" body={tagBodyField}></Column>
                    <Column header="Image" body={imageBodyTemplate}></Column>
                </DataTable>
            </div>
        </>
    )
}

export default Categories