import React, { useRef } from 'react'
import { Box, FormControlLabel, Switch, Grid } from "@mui/material";
import { useEditor } from "@craftjs/core";
import { Button } from 'primereact/button';
import lz from "lzutf8";
import { confirmPopup } from 'primereact/confirmpopup';
import { addDoc, collection, deleteDoc, doc, Timestamp, updateDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { Toast } from 'primereact/toast';


export const ControlBar = ({ page, dropdownValue, html }) => {
    const toast = useRef(null);
    const { actions, query, enabled } = useEditor((state) => ({
        enabled: state.options.enabled
    }));
    const HandleUpload = async () => {
        try {
            const json = query.serialize();
            await addDoc(collection(db, 'PageTemplate'), {
                title: page.name,
                categorie: page.category,
                description: page.description,
                html: html,
                content: lz.encodeBase64(lz.compress(json)),
                createdBy: auth.currentUser.uid,
                createdAt: Timestamp.fromDate(new Date()),
            })
            toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'Data has been sent ;)', life: 3000 });

        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: error, life: 3000 })
        }
    }
    const deleteScript = async () => {
        try {
            await deleteDoc(doc(db, 'PageTemplate', dropdownValue.id))
            toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'Document has been deleted successfully ;)', life: 3000 });
        } catch (error) {
            console.log(error)
            toast.current.show({ severity: 'error', summary: 'Error', detail: "This document hasn't been saved yet", life: 3000 });
        }
    }
    const reject = () => {
        toast.current.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };
    const handleDelete = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
            accept: deleteScript,
            reject
        });
    }
    const HandleUpdate = async () => {
        const json = query.serialize();
        await updateDoc(doc(db, 'PageTemplate', dropdownValue.id), {
            title: page.name,
            categorie: page.category,
            description: page.description,
            html: html,
            content: lz.encodeBase64(lz.compress(json))
        })
        toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'Data has been updated ;)', life: 3000 });
    }
    return (
        <>
            <Toast ref={toast} />
            <Box px={1} py={1} mt={3} mb={1} bgcolor="#fff">
                <Grid container alignItems="center">
                    <Grid item xs>
                        <FormControlLabel
                            control={<Switch checked={enabled} onChange={(_, value) => actions.setOptions(options => options.enabled = value)} />}
                            label="Enable"
                        />
                    </Grid>
                    <Grid item>
                        {dropdownValue &&
                            <Button
                                label='Update existing Data'
                                className='mr-2 p-button-success'
                                icon='pi pi-fw pi-save'
                                onClick={HandleUpdate}
                            >
                            </Button>}
                        <Button
                            onClick={handleDelete}
                            icon="pi-trash"
                            label="Delete"
                            className="p-button-danger mr-2"
                        />
                        <Button
                            label='Save Data'
                            className=''
                            icon='pi pi-fw pi-send'
                            onClick={HandleUpload}
                        >
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
