import { useNode } from '@craftjs/core';
import { FormControl, FormLabel, TextField } from '@mui/material';
import { InputNumber } from 'primereact/inputnumber';
import React from 'react'

export const YTVideo = ({ width, height, src, title }) => {
    const { connectors: { connect, drag } } = useNode();
    return (
        <div ref={ref => connect(drag(ref))}>
            <iframe
                width={`${width}%` || "790"}
                height={`${height}px` || "444"}
                src={src || "https://www.youtube.com/embed/K4TOrB7at0Y"}
                title={title || "Demo Background Sample Video"}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>
            </iframe>
        </div>
    )
}

export const YTVideoSetting = () => {
    const { actions: { setProp }, props } = useNode((node) => ({
        props: node.data.props
    }));

    return (
        <>
            <FormControl fullWidth={true} margin="normal" component="fieldset">
                <FormLabel component="legend">Height</FormLabel>
                <InputNumber value={props.height} onChange={(e) => setProp(props => props.height = e.value)} />
            </FormControl>
            <FormControl fullWidth={true} margin="normal" component="fieldset">
                <FormLabel component="legend">Width</FormLabel>
                <InputNumber value={props.width} onChange={(e) => setProp(props => props.width = e.value)} />
            </FormControl>
            <FormControl fullWidth={true} margin="normal" component="fieldset">
                <FormLabel component="legend">Youtube link Source</FormLabel>
                <TextField label="Youtube link Source" onChange={e => setProp(props => props.src = e.target.value)}></TextField>
            </FormControl>
            <FormControl fullWidth={true} margin="normal" component="fieldset">
                <FormLabel component="legend">Title</FormLabel>
                <TextField label="Video Title" onChange={e => setProp(props => props.title = e.target.value)}></TextField>
            </FormControl>
        </>
    )
}

YTVideo.craft = {
    related: {
        settings: YTVideoSetting
    }
}