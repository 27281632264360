import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import HtmlEditor from './Editor'
import { Button } from 'primereact/button';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { Panel } from 'primereact/panel';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { confirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import { addDoc, collection, Timestamp, onSnapshot, query, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Cancel } from '@mui/icons-material';

const EmptyPage = () => {
    const editorRef = useRef(null);
    const [dropdownValue, setDropdownValue] = useState(null);
    const [dropdownValues, setDropdownValues] = useState([]);
    const [html, setHtml] = useState('Add your HTML code here')
    const [css, setCss] = useState('Customize your HTML code with css here')
    const [js, setJs] = useState('Add javascript code here')
    const [title, setTitle] = useState('')
    const [sources, setSources] = useState('This is the header of the document where you can add custom file like "boostrap cdn"')
    const [srcDoc, setSrcDoc] = useState('')
    const [value, setValue] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [updateState, setUpdateState] = useState(false)
    const [id, setId] = useState('')
    const [pagetype, setPagetype] = useState('')
    const [pagetypeValue, setPagetypeValue] = useState('')
    const [vignette, setVignette] = useState('')
    const toast = useRef(null);

    useEffect(() => {
        const contentRef = collection(db, 'PageContent');
        const q = query(contentRef)
        onSnapshot(q, querySnapshot => {
            setDropdownValues(querySnapshot.docs.map((doc) => ({
                data: doc.data(),
                id: doc.id,
                label: doc.data().title
            }
            )));
        })
        // setPagetype([
        //     { label: 'Website Page' },
        //     {
        //         label: 'Blog',
        //         html: `<header class="banner playback_audio_title">
        //     <h1 class="titleHeader">Ceci est un test de blog</h1>
        //     <img src=${vignette ? URL.createObjectURL(vignette) : 'assets/demo/images/galleria/galleria11.jpg'} alt="template" />
        //     </header>`,
        //         css: `.banner {
        //         background-color: #2a294b;
        //         margin: 0;
        //         height: 350px;
        //         position: relative;
        //         text-align: center;
        //       }
        //       .banner img {
        //         width: 100%;
        //         height: 100%;
        //         object-fit: cover;
        //     }
        //       .banner .titleHeader {
        //         font-size: 4em;
        //         margin: 0;
        //         left: 0;
        //         padding: 1em 0;
        //         transform: inherit;
        //         text-align: center;
        //         text-shadow: 0 0 4px rgb(0 0 0 / 50%);
        //         text-transform: uppercase;
        //         transform: translateY(-50%);
        //         top: 50%;
        //         font-weight:700;
        //         color:white;
        //         position: absolute;
        //             width: 100%;
        //         text-align: center;
        //         text-shadow: 0 0 4px rgb(0 0 0 / 50%);
        //       }
        //       p.lead {
        //         font-size: 2em;
        //         font-weight: 400;
        //       }`
            // },
        // ])
    }, [vignette])

    const handleChange = (e) => {
        setTitle(e.target.value)
    }

    const accept = async () => {
        if (updateState) {
            try {
                await updateDoc(doc(db, 'PageContent', id), {
                    title: title,
                    sources: sources,
                    html: html,
                    css: css,
                    script: js || '',
                    srcDoc: srcDoc,
                });
                toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'Document has been updated successfully ;)', life: 3000 });
            } catch (err) {
                console.log(err.message);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'there was an error on the update', life: 3000 });
            }
        } else {
            await addDoc(collection(db, 'PageContent'), {
                title,
                sources,
                html,
                css,
                script: js || '',
                srcDoc,
                createdAt: Timestamp.fromDate(new Date()),
            })
            toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'Data has been sent ;)', life: 3000 });
        }

    };

    const reject = () => {
        toast.current.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };

    const confirm = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
            accept,
            reject
        });
    };

    const deleteScript = async () => {
        try {
            await deleteDoc(doc(db, 'PageContent', id))
            setDropdownValue('')
            setValue('')
            setHtml('New Document to edit')
            setCss('')
            setJs('')
            setSources('')
            setTitle('')
            setUpdateState(false)
            toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'Document has been deleted successfully ;)', life: 3000 });
        } catch (error) {
            console.log(error)
            toast.current.show({ severity: 'error', summary: 'Error', detail: "This document hasn't been saved yet", life: 3000 });
        }
    }

    const handleDelete = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
            accept: deleteScript,
            reject
        });
    }

    const selectPage = (e) => {
        setPagetypeValue(e.value)
        setHtml(e.value.html)
        setCss(e.value.css)
        setSources('')
    }
    const selected = (e) => {
        setId(e.value.id)
        setDropdownValue(e.value)
        setHtml(e.value.data.html)
        setValue(e.value.data.html)
        setCss(e.value.data.css)
        setJs(e.value.data.js)
        setSources(e.value.data.sources)
        setTitle(e.value.data.title)
        setUpdateState(true)
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            setSrcDoc(`
          <html>
            <head>${sources}</head>
            <body>${html}</body>
            <style>${css}</style>
            <script>${js}</script>
          </html>
        `)
        }, 250)
        return () => clearTimeout(timeout)
    }, [html, css, js, sources])

    const click = () => {
        setValue(html)
    }

    const validatePhoto = () => {
        setHtml(pagetypeValue.html)
    }

    const agree = () => {
        toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'New Document Ready ;)', life: 3000 });
        setDropdownValue('')
        setHtml('New Document to edit')
        setValue('')
        setCss('')
        setJs('')
        setSources('')
        setTitle('')
        setUpdateState(false)
    }

    const handleReset = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to proceed? Any unsaved change will be lost',
            icon: 'pi pi-exclamation-triangle',
            accept: agree,
            reject
        });
    }
    return (
        <>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-12">
                    <div className='card'>
                        <h5>Select the page to modify</h5>
                        <Dropdown
                            value={dropdownValue}
                            onChange={selected}
                            options={dropdownValues}
                            optionLabel='label'
                            placeholder="Select"
                        />
                    </div>
                    <div className="card">
                        <h5>Page Title</h5>
                        <div className="mb-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <InputText placeholder="Page Title" value={title} onChange={handleChange} />
                            <Dropdown
                                value={pagetypeValue}
                                onChange={selectPage}
                                options={pagetype}
                                optionLabel='label'
                                placeholder='Select Page Type'
                            />
                            <Button label="New Content" className="p-button-success mr-2 mb-2" onClick={handleReset} />
                        </div>
                        <Panel header='Developer Input' toggleable>
                            <div className="pane top-pane">
                                <HtmlEditor
                                    language="xml"
                                    displayName="SOURCES FILE"
                                    value={sources}
                                    onChange={setSources}
                                />
                                <HtmlEditor
                                    language="xml"
                                    displayName="HTML"
                                    value={html}
                                    onChange={setHtml}
                                />
                                <HtmlEditor
                                    language="css"
                                    displayName="CSS"
                                    value={css}
                                    onChange={setCss}
                                />
                                <HtmlEditor
                                    language="javascript"
                                    displayName="JS"
                                    value={js}
                                    onChange={setJs}
                                />
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button label="Validate" type="button" onClick={click} ></Button>
                                </div>
                            </div>
                        </Panel>
                    </div>
                    <div className='card'
                        style={{ display: `${pagetypeValue.label === 'Blog' ? 'block' : 'none'}` }}
                    >
                        <Panel header="Upload vignette here" toggleable>
                            <label htmlFor="formFile" style={{ marginTop: "10px", cursor: "pointer" }}>
                                <AddAPhotoIcon fontSize="large" />
                            </label>
                            <input type="file" name="" id="formFile" style={{ display: "none" }} accept='image/*' onChange={(e) => setVignette(e.target.files[0])} />
                            {vignette && (
                                <>
                                    <hr />
                                    <div className="shareImgContainer">
                                        <img className="shareImgUp" src={URL.createObjectURL(vignette)} alt="" />
                                        <Cancel className="shareCancelImg" onClick={() => setVignette(null)} />
                                    </div>
                                </>
                            )}
                            <Button label='Validate' style={{ display:`${vignette? 'block' : 'none'}` }} onClick={validatePhoto}/>
                        </Panel>
                    </div>
                    <div className='card'>
                        <Panel header="Raw Output & Text Editor" toggleable>
                            <Editor
                                apiKey={process.env.REACT_APP_EDITOR_API_KEY}
                                onInit={(evt, editor) => editorRef.current = editor}
                                initialValue={value || '<p>Please click on the validate button on the developer section to start editing text here</p>'}
                                onEditorChange={(newValue, editor) => setHtml(newValue)}
                                init={{
                                    selector: 'textarea',
                                    height: 600,
                                    menubar: true,
                                    image_title: true,
                                    plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount',
                                    ],
                                    toolbar: 'undo redo | blocks ' +
                                        'bold italic forecolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | image | table | link | preview',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            />
                            <div style={{ textAlign: 'right', marginTop: '10px' }}>
                                <Button
                                    onClick={confirm}
                                    icon={updateState ? "pi pi-save" : "pi pi-send"}
                                    label={updateState ? "Update" : "Save"}
                                    className={updateState ? "p-button-success mr-2" : "mr-2"}
                                />
                                <Button
                                    onClick={handleDelete}
                                    icon="pi-trash"
                                    label="Delete"
                                    className="p-button-danger mr-2"
                                />
                            </div>
                        </Panel>
                    </div>
                    <div className="card" >
                        <h5>Live preview</h5>
                        <Button label="Show" icon="pi pi-external-link" className='p-button-info' onClick={() => setShowModal(true)} />
                        <Dialog
                            style={{ width: '50vw', background: 'white', color: 'black' }}
                            header="Page Live Preview"
                            maximizable={true}
                            blockScroll={true}
                            visible={showModal}
                            maximized={true}
                            modal
                            onHide={() => setShowModal(false)}
                        >
                            <div dangerouslySetInnerHTML={{ __html: srcDoc }} />
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(EmptyPage, comparisonFn);