import React from "react";
import { Image } from './Image'
import { RichText } from './RichText'
import { Container } from "./Container";
import { useNode, Element } from "@craftjs/core";
import { ContainerSettings } from "./Container";
import { ContainerDefaultProps } from "./Container";
import defaultImg from './image/default.jpg'

export const Card = ({ background, padding = 20 }) => {
  const css = (`
  .cardImg {
    transition: 0.5s all ease;
    position: relative;
  }
  .cardImg:hover {
    transform: scale(1.1);
  }
  .containere{
    overflow:hidden;
  }
  `)
  return (
    <>
      <Container paddingBottom={0} paddingLeft={0} paddingRight={0} paddingTop={0} flexDirection="column" display="flex">
        <Container paddingBottom={0} paddingLeft={0} className="containere" paddingRight={0} paddingTop={0}>
          <Element is={Image} id="carditm" className="cardImg" width={100} src={defaultImg} />
        </Container>
        <Container paddingBottom={30} background="white" paddingLeft={30} textAlign='center' paddingRight={30} paddingTop={30}>
          <Element is={RichText} id="cardtxt" srcDoc={'<p>This is Rich text sample</p>'} />
        </Container>
      </Container>
      <style>
        {css}
      </style>
    </>
  )
}