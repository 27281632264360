import React from 'react';
import classNames from 'classnames';
import { Button } from "primereact/button";
import { Divider } from '@mui/material';
import { SettingsPanel } from './components/SettingsPanel';
import { Panel } from 'primereact/panel';
import { Layers } from '@craftjs/layers';

export const SettingBuild = ({ active, setActive }) => {
    
    const toggleConfigurator = (event) => {
        setActive(!active);
    }

    const configClassName = classNames('setting-config', {
        'setting-config-active': active
    })
    return (
        <div className={configClassName} id={"setting-config"}>
            <button className="setting-config-button p-link" id="setting-config-button" onClick={toggleConfigurator}>
                <i className="pi pi-fw pi-pencil"></i>
            </button>
            <Button className="p-button-danger setting-config-close p-button-rounded p-button-text" icon="pi pi-times" onClick={e => setActive(false)} />
            <div className="layout-config-content"style={{ overflow:"auto",height: '90vh' }}>
                <Panel toggleable header="Layer">
                    <Layers />
                </Panel>
                <Divider />
                <SettingsPanel />
            </div>
        </div>
    );
}
