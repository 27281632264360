import React, { useEffect, useState } from "react";
import { useNode } from "@craftjs/core";
import { FormControl, FormLabel, FormControlLabel, InputLabel, Select, MenuItem, TextField } from "@mui/material";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { InputNumber } from "primereact/inputnumber";
import { Link } from "react-router-dom";

export const MaterialButton = ({
  label,
  color,
  fontSize,
  fontFamily,
  paddingTB,
  paddingLR,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  borderRadius,
  Hovercolor,
  HoverBackground,
  background,
  borderThick,
  borderType,
  borderColor,
  link
}) => {
  const [id, setId] = useState('')
  useEffect(() => {
    const makeid = () => {
      var text = "";
      var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

      for (var i = 0; i < 5; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text

    }
    setId(makeid())
  }, [])
  const css = (`
  #${id} a{
    color:${color};
  }
  #${id}:hover > a{
    color: ${Hovercolor};
  }
  #${id}{
    background:${background};
    font-size: ${fontSize}px;
    font-family: ${fontFamily};
    padding-top: ${paddingTB}px ;
    padding-bottom: ${paddingTB}px ;
    padding-left: ${paddingLR}px ;
    padding-right: ${paddingLR}px ;
    margin-top: ${marginTop}px ;
    margin-bottom: ${marginBottom}px ;
    margin-left: ${marginLeft}px ;
    margin-right: ${marginRight}px ;
    border-radius:${borderRadius}px;
    border:${borderThick}px ${borderType || 'solid'} ${borderColor || 'black'};
    transition: all .5s ease;
  }
  #${id}:hover {
    background: ${HoverBackground};
}
  `)
  const { connectors: { connect, drag } } = useNode();
  return (
    <>
      <button id={id} ref={ref => connect(drag(ref))} className='mr-2 mb-2' >
        <Link to={link || '#'}>{label}</Link>
      </button>
      <style>
        {css}
      </style>
    </>
  )
}


const ButtonSettings = () => {
  const { actions: { setProp }, props } = useNode((node) => ({
    props: node.data.props
  }));

  return (
    <div>
      <FormControl component="fieldset" fullWidth className="mb-3">
        <FormLabel component="legend">Label</FormLabel>
        <InputText id="" type="text" value={props.label || ''} onChange={(e) => setProp(props => props.label = e.target.value)} />
      </FormControl>
      <FormControl component="fieldset" fullWidth className="mb-3">
        <FormLabel component="legend">link</FormLabel>
        <InputText id="" type="text" value={props.link || ''} onChange={(e) => setProp(props => props.link = e.target.value)} />
      </FormControl>
      <FormControl fullWidth={true} margin="normal" component="fieldset">
        <FormLabel component="legend">Text Color</FormLabel>
        <InputText value={props.color || '#fff'} onChange={e => { setProp(props => props.color = e.target.value) }} />
      </FormControl>
      <FormControl fullWidth={true} margin="normal" component="fieldset">
        <FormLabel component="legend">Text Color on Hover</FormLabel>
        <InputText value={props.Hovercolor || '#fff'} onChange={e => { setProp(props => props.Hovercolor = e.target.value) }} />
      </FormControl>
      <FormControl fullWidth={true} margin="normal" component="fieldset">
        <FormLabel component="legend">Background Color</FormLabel>
        <InputText value={props.background || '#fff'} onChange={e => { setProp(props => props.background = e.target.value) }} />
      </FormControl>
      <FormControl fullWidth={true} margin="normal" component="fieldset">
        <FormLabel component="legend">Background Color on Hover</FormLabel>
        <InputText value={props.HoverBackground || '#fff'} onChange={e => { setProp(props => props.HoverBackground = e.target.value) }} />
      </FormControl>
      <FormControl fullWidth={true} margin="normal" component="fieldset">
        <Panel toggleable header="Padding">
          <FormLabel component="legend">Padding Top & Bottom</FormLabel>
          <InputNumber value={props.paddingTB} onChange={(e) => setProp(props => props.paddingTB = e.value)} />
          <FormLabel component="legend">Padding Left & Right</FormLabel>
          <InputNumber value={props.paddingLR} onChange={(e) => setProp(props => props.paddingLR = e.value)} />
        </Panel>
      </FormControl>
      <FormControl fullWidth={true} margin="normal" component="fieldset">
        <Panel toggleable header="Margin">
          <FormLabel component="legend">Margin Top</FormLabel>
          <InputNumber value={props.marginTop} onChange={(e) => setProp(props => props.marginTop = e.value)} />
          <FormLabel component="legend">Margin Bottom</FormLabel>
          <InputNumber value={props.marginBottom} onChange={(e) => setProp(props => props.marginBottom = e.value)} />
          <FormLabel component="legend">Margin Left</FormLabel>
          <InputNumber value={props.marginLeft} onChange={(e) => setProp(props => props.marginLeft = e.value)} />
          <FormLabel component="legend">Margin Right</FormLabel>
          <InputNumber value={props.marginRight} onChange={(e) => setProp(props => props.marginRight = e.value)} />
        </Panel>
      </FormControl>
      <FormControl component="fieldset" fullWidth margin="normal">
        <InputLabel id="demo-simple-select-label">Font Family</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.fontFamily}
          label="Type"
          onChange={(e) => setProp(props => props.fontFamily = e.target.value)}
        >
          <MenuItem value="'Roboto', sans-serif">Roboto</MenuItem>
          <MenuItem value="'League Gothic', sans-serif">League Gothic</MenuItem>
          <MenuItem value="'Poppins', sans-serif">Poppins</MenuItem>
          <MenuItem value="'Staatliches', cursive">Staatliches</MenuItem>
          <MenuItem value="'Smooch', cursive">Smooch</MenuItem>
          <MenuItem value="'Fredoka', sans-serif">Fredoka</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal" component="fieldset">
        <FormLabel component="legend">Font size</FormLabel>
        <InputNumber value={props.fontSize} onChange={(e) => { setProp(props => props.fontSize = e.value) }} />
      </FormControl>
      <FormControl fullWidth margin="normal" component="fieldset">
        <FormLabel component="legend">Border Radius</FormLabel>
        <InputNumber value={props.borderRadius} onChange={(e) => { setProp(props => props.borderRadius = e.value) }} />
      </FormControl>
      <FormControl fullWidth style={{ display:'flex', justifyContent:'space-between', flexDirection: 'row' }} margin="normal" component="fieldset">
        <FormLabel component="legend">Border</FormLabel>
        <TextField type='number' style={{ width:'100px' }} label='Border width' value={props.borderThick} onChange={(e) => { setProp(props => props.borderThick = e.target.value) }} />
        <Select
          id="demo-simple-select"
          value={props.borderType || 'solid'}
          onChange={(e) => setProp(props => props.borderType = e.target.value)}
          style={{ width:'100px' }}
        >
          <MenuItem value="solid">Solid</MenuItem>
          <MenuItem value="dashed">Dashed</MenuItem>
          <MenuItem value="dotted">dotted</MenuItem>
        </Select>
        <TextField label='Border Color' style={{ width:'66px' }} value={props.borderColor || 'black'} onChange={e => { setProp(props => props.borderColor = e.target.value) }} />
      </FormControl>
    </div>
  )
};

MaterialButton.craft = {
  props: {
    variant: "",
    color: "",
    label: "Click me"
  },
  related: {
    settings: ButtonSettings
  }
}